<div class="profile-picture">
  <div class="portrait-container" *ngIf="imgSrc; else text">
    <img class="portrait" [alt]="fullName || 'Portrait'" [src]="imgSrc | safe" />
  </div>
  <ng-template #text>
    <div *ngIf="fullName; else fallback" class="circle">
      <span class="initials">{{ initials }}</span>
    </div>
  </ng-template>
  <ng-template #fallback>
    <div class="circle">
      <mat-icon class="account-icon" svgIcon="account"></mat-icon>
    </div>
  </ng-template>
</div>
