<div class="body" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxLayoutAlign.lt-md="space-between stretch">
  <!-- Filter for mobile -->
  <div
    class="filter"
    [class.search-mode]="searchMode"
    *ngIf="display.isMobile$ | async"
    fxLayout="row"
    fxLayoutAlign="space-around center"
    matSort
    matSortDisableClear="true"
    matSortActive="sentDate"
    matSortDirection="desc"
  >
    <div class="search" fxLayout="row">
      <mat-icon svgIcon="magnify" class="magnifier" (click)="searchMode = true"></mat-icon>
      <input gcAutofocus type="text" name="search" placeholder="Search notifications..." [formControl]="searchControl" />
      <mat-icon svgIcon="close" class="close-search" (click)="searchMode = false"></mat-icon>
    </div>
    <gc-type-filter minimal="true" [data]="_notifications" (filterCallback)="onFilter($event)" [types]="typeFilters"></gc-type-filter>
    <p mat-sort-header="date" mat-sort-start="desc">Date</p>
  </div>
  <!-- Filter for desktop -->
  <div class="filter" fxHide.lt-md>
    <div class="filter-header">
      <gc-search-input [formControl]="searchControl" placeholder="Search notifications..."></gc-search-input>
      <h2 class="heading-1">Filter</h2>
    </div>
    <gc-type-filter [data]="_notifications" (filterCallback)="onFilter($event)" [types]="typeFilters"></gc-type-filter>
  </div>
  <div class="notifications-container" fxLayout="column" fxLayoutAlign="space-between stretch">
    <div class="notifications" fxFlex="grow">
      <ng-container
        *ngIf="(display.isMobile$ | async) === false"
        matSort
        matSortDisableClear="true"
        matSortActive="sentDate"
        matSortDirection="desc"
      >
        <p mat-sort-header="date" fxHide.lt-md>Date</p>
      </ng-container>
      <mat-accordion class="accordion" multi="true" hideToggle="true" *ngIf="notifications$ | async as notifications">
        <mat-expansion-panel
          #notificationPanel
          class="notification"
          [class.unread]="!n.readDate"
          [class]="n.type"
          [class.action-required]="n.actionRequired"
          *ngFor="let n of notifications; let i = index"
        >
          <mat-expansion-panel-header class="header">
            <mat-panel-title>
              <mat-icon [color]="n.actionRequired ? 'warn' : 'accent'" [svgIcon]="n.icon" class="icon"></mat-icon>
            </mat-panel-title>
            <mat-panel-description>
              <div fxLayout="column" fxLayoutGap="3px" fxFlex="100">
                <span class="date">{{ n.sentDate | localizedDate : 'fullDate' }}</span>
                <span class="description">{{ n.description }}</span>
              </div>
              <button class="action" mat-button (click)="onNotificationActionClick($event, n)">
                {{ n.actionName || 'view' }}
              </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-container *ngIf="display.isMobile$ | async">
            <button
              class="action"
              *ngIf="['telemed', 'questionnaire', 'consent-form', 'payment'].includes(n.type)"
              mat-button
              (click)="onNotificationActionClick($event, n)"
            >
              {{ n.actionName || 'view' }}
            </button>
          </ng-container>
        </mat-expansion-panel>
        <div fxLayout="row" fxLayoutAlign="center" *ngIf="!notifications.length">
          <h2>{{ noNotifications ? 'No new notifications' : 'Loading notifications...' }}</h2>
        </div>
      </mat-accordion>
    </div>
    <mat-paginator class="bottom" [pageSizeOptions]="[10, 20, 100]"></mat-paginator>
  </div>
</div>
