import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root',
})
export class TagManagerService {
  lastTime: number;

  constructor(private readonly gtmService: GoogleTagManagerService) {
    this.lastTime = window.performance.now();
  }

  pushTag(gtmTag) {
    const timing = window.performance.now() - this.lastTime;
    this.lastTime = window.performance.now();
    gtmTag = {
      ...gtmTag,
      timing,
    };
    this.gtmService.pushTag(gtmTag);
  }
}
