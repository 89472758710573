import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gc-breadcrumb-bar',
  templateUrl: './breadcrumb-bar.component.html',
  styleUrls: ['./breadcrumb-bar.component.scss'],
})
export class BreadcrumbBarComponent implements OnInit {
  @Input() crumbs: string[];
  @Output() backEvent = new EventEmitter<string>();
  @Output() selectEvent = new EventEmitter<number>();
  @Input() defaultBackFunction = true;
  @Input() selectedIndex: number;

  ngOnInit() {
    this.selectedIndex = this.selectedIndex ?? this.crumbs.length - 1;
  }

  onBackClick() {
    this.backEvent.emit();
  }

  onSelectClick(index: number) {
    this.selectedIndex = index;
    this.selectEvent.emit(index);
  }
}
