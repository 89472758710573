<div class="search-container" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="close" fxLayout="column" fxLayoutAlign="space-around end" (click)="closeOverlay()">
    <button mat-icon-button aria-label="Close">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <div class="search">
    <gc-search-input [autofocus]="true" [formControl]="searchBar" placeholder="Can we help you find something?"></gc-search-input>
  </div>
  <ng-container *ngIf="results$ | async as results">
    <h3 *ngIf="searchTerm; else suggestions">We found these results:</h3>
    <ng-template #suggestions>
      <h3>Here are some suggestions:</h3>
    </ng-template>
    <div class="results">
      <div *ngFor="let result of results">
        <ng-container [ngSwitch]="result.type">
          <div *ngSwitchCase="'doctor'">
            <div class="doctor rounded">
              <gc-doctor-card [doctor]="result.item" [minimal]="true"></gc-doctor-card>
            </div>
          </div>
          <div *ngSwitchCase="'route'">
            <div class="navigation-card">
              <mat-card>
                <mat-card-content fxLayout="row" fxLayoutAlign="center center">
                  <mat-icon [svgIcon]="result.item.icon" fxFlex="10"></mat-icon>
                  <div fxFlex="grow">
                    {{ result.item.phrase }}
                  </div>
                  <button mat-mini-fab aria-label="Go" fxFlex="10" [routerLink]="result.item.path" (click)="closeOverlay()">
                    <mat-icon svgIcon="chevron-right"></mat-icon>
                  </button>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div class="help" fxLayout="column" fxLayoutAlign="space-between center">
    <span>Not what you were looking for?</span>
    <button routerLink="/contact-us" mat-stroked-button aria-label="Contact us">
      <mat-icon svgIcon="comment-question"></mat-icon>
      Ask us directly
    </button>
  </div>
</div>
