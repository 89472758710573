import { Location } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Payment, ThemeData } from '@models/account.model';
import { dialogMessage } from '@models/dialog-communication.model';
import { LoggingService } from '@services/logging.service';
import { PaymentDialogComponent } from '../view-payment.component';

export interface PaymentOptionData {
  name: string; // Displayed name
  value: string; // Value to test
  url: string; // Payment url if one is pre-generated
  logo: string; // image located in src/images/payments
}

@Component({
  selector: 'gc-view-payment-options',
  templateUrl: './view-payment-options.component.html',
  styleUrls: ['./view-payment-options.component.scss'],
})
export class ViewPaymentOptionsComponent implements OnChanges {
  @Input() payment: Payment;
  @Input() payAllInvoices: boolean;
  @Input() url: string;
  @Input() theme: ThemeData;
  selectedPayment: Payment;
  step = 1;
  paymentOptions: PaymentOptionData[];
  public selectedPaymentOption: FormControl;

  constructor(
    public dialog: MatDialog,
    private readonly sanitizer: DomSanitizer,
    private readonly logger: LoggingService,
    public location: Location,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnChanges(): void {
    this.selectedPaymentOption = new FormControl('');
    this.paymentOptions = [];
    if (this.payment) {
      if (this.payment.ozowPaymentUrl) {
        const ozow = {
          value: 'ozow',
          name: 'Ozow',
          url: this.payment.ozowPaymentUrl,
          logo: 'assets/images/payments/ozow-logo.svg',
        };
        this.paymentOptions.push(ozow);
      }

      if (this.payment.peachpayPaymentUrl) {
        const peachpay = {
          value: 'peachpay',
          name: 'Peach Payments',
          url: this.payment.peachpayPaymentUrl,
          logo: 'assets/images/payments/peachpay-logo.svg',
        };
        this.paymentOptions.push(peachpay);
      }
      if (this.payment.fnbPaymentUrl) {
        const fnb = {
          value: 'fnb',
          name: 'FNB',
          url: this.payment.fnbPaymentUrl,
          logo: 'assets/images/payments/fnb-logo.png',
        };
        this.paymentOptions.push(fnb);
      }
      if (this.payment.stripePaymentUrl) {
        const stripe = {
          value: 'stripe',
          name: 'Stripe',
          url: this.payment.stripePaymentUrl,
          logo: 'assets/images/payments/stripe-logo.svg',
        };
        this.paymentOptions.push(stripe);
      }
    }
  }

  selectPayment() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        type: null,
        hash: this.payment.hash,
      },
      // preserve the existing query params in the route
      queryParamsHandling: 'merge',
      // do not trigger navigation
      skipLocationChange: true,
    });
  }

  openPayment() {
    let paymentLink = '';
    const paymentMethod = this.selectedPaymentOption.value;
    if (paymentMethod === 'ozow' && this.payment.ozowPaymentUrl) {
      paymentLink = this.payment.ozowPaymentUrl;
    } else if (paymentMethod === 'peachpay' && this.payment.peachpayPaymentUrl) {
      paymentLink = this.payment.peachpayPaymentUrl;
    } else if (paymentMethod === 'fnb' && this.payment.fnbPaymentUrl) {
      paymentLink = this.payment.fnbPaymentUrl;
    } else if (paymentMethod === 'stripe' && this.payment.stripePaymentUrl) {
      paymentLink = this.payment.stripePaymentUrl;
    } else {
      return;
    }

    this.logger.addToJourney('action/open-payment-iframe');
    const paymentUrlBuild = new URL(paymentLink);
    paymentUrlBuild.searchParams.append('payment_notify_url', window.location.origin + this.url);
    const paymentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(paymentUrlBuild.href);
    const dialogRef = this.dialog.open(PaymentDialogComponent, {
      width: '650px',
      data: { paymentUrl },
    });

    const handleListen = (event: MessageEvent) => {
      if (event.origin === window.origin && event.data === dialogMessage.close) {
        dialogRef.close();
      }
    };

    window?.addEventListener('message', handleListen.bind(this));

    //After the iframe is closed
    dialogRef?.afterClosed().subscribe(() => {
      window?.location.reload();
    });
  }

  backPayment() {
    this.selectedPayment = null;
  }
}
