interface Theme {
  logo: string;
  theme: { primary: string; accent: string };
}

export interface PracticeData extends Theme {
  uuid: string;
  name: string;
  contact_numbers: string[];
  email_addresses: string[];
  terms_and_conditions: string;
}

export class Practice {
  uuid: string;
  name: string;
  contactNumbers: string[];
  emailAddresses: string[];
  logo: string;
  termsAndConditions: string;
  theme: { primary: string; accent: string };

  constructor(data: PracticeData) {
    this.uuid = data.uuid;
    this.name = data.name;
    this.contactNumbers = data.contact_numbers;
    this.emailAddresses = data.email_addresses;
    this.logo = data.logo;
    this.termsAndConditions = data.terms_and_conditions;
    this.theme = data.theme;
  }
}
