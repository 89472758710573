import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'gc-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  constructor(private readonly location: Location) {}
  onBackClick() {
    return this.location.back();
  }
}
