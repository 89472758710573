<div
  (pan)="handlePan($event)"
  (panend)="handlePanEnd($event)"
  class="cards"
  [style.zIndex]="maxRenderedCardCount"
  *ngIf="notifications.length; else loadingNotifications"
>
  <div
    #swipeCard
    (transitionend)="handleShift()"
    *ngFor="let notification of notifications.slice(0, maxRenderedCardCount); let i = index"
    class="card"
    [class.unread]="!notification.readDate"
    [class.action-required]="notification.actionRequired"
    [class.front-card]="i === 0"
    [ngStyle]="getCardStyle(i)"
  >
    <!-- Only render text details on top 2 cards -->
    <div fxLayout="column" *ngIf="notification && i < 2">
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center start" class="card-info">
        <div fxFlex="0 1 36px">
          <mat-icon class="card-icon" [svgIcon]="notification.icon"></mat-icon>
        </div>
        <div fxFlex="grow" class="card-text">
          <div class="card-date">
            {{ notification.sentDate | localizedDate : 'shortTime' }} on {{ notification.sentDate | localizedDate : 'fullDate' }}
          </div>
          <div class="card-message" [class.only-message]="notification.type === 'message'">
            {{ notification.description }}
          </div>
        </div>
        <div fxFlex="0 1 3px">
          <button class="close" mat-icon-button (click)="dismissCard($event, notification)">
            <mat-icon svgIcon="close"></mat-icon>
          </button>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end">
        <button class="action" mat-raised-button type="button" color="primary" (click)="doNotification(notification)">
          {{ notification.actionName || 'Dismiss' }}
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingNotifications>
  <div fxLayout="column" fxLayoutGap="10px" *ngIf="isLoading; else noNotifications">
    <span>Loading notifications..</span>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <ng-template #noNotifications>
    <div>No new notifications</div>
  </ng-template>
</ng-template>
