import Integer from '@zxing/library/esm/core/util/Integer';
import { Question } from './question.model';

export const DEFAULT_RATING: Question<Integer> = {
  required: true,
  key: 'rating',
  type: 'rating',
  label: 'rating',
  show: () => true,
  options: [
    { value: 1, label: 'Terrible', icon: 'emoticon-frown-outline' },
    { value: 2, label: 'Bad', icon: 'emoticon-sad-outline' },
    { value: 3, label: 'Okay', icon: 'emoticon-neutral-outline' },
    { value: 4, label: 'Good', icon: 'emoticon-happy-outline' },
    { value: 5, label: 'Great', icon: 'emoticon-excited-outline' },
  ],
};

export interface Feedback {
  id: number;
  context: string;
  rating: number;
  contact: boolean;
  contact_method: string;
  cellnr: string;
  comment: string;
}
