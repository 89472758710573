import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'gc-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements ControlValueAccessor, AfterViewInit {
  @Input() placeholder = '';
  @Input() autofocus = false;
  @Output() clearClick = new EventEmitter<MouseEvent>();
  @Output() inputBlur = new EventEmitter<void>();
  @ViewChild('field') field: ElementRef<HTMLInputElement>;
  value = '';

  constructor(
    @Self()
    @Optional()
    public control: NgControl,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  onChange = (_value: any) => {}; // eslint-disable-line  no-empty,@typescript-eslint/no-empty-function
  onTouched = () => {}; // eslint-disable-line  no-empty, @typescript-eslint/no-empty-function

  @HostListener('focus')
  onFocus() {
    this.focus();
  }

  ngAfterViewInit() {
    if (this.autofocus) {
      this.focus();
    }
  }

  _onBlur() {
    this.onTouched();
    this.inputBlur.emit();
  }

  _onChange(event: Event) {
    this.update((event.target as HTMLInputElement).value);
  }

  private update(value: any): void {
    if (this.value !== value) {
      this.value = value;
      this.onChange(value);
    }
  }

  writeValue(value: any): void {
    this.update(value);
  }

  registerOnChange(fn: (val: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  clear(event: MouseEvent): void {
    this.writeValue('');
    this.focus();
    this.clearClick.emit(event);
  }

  focus() {
    this.field.nativeElement.focus();
  }
}
