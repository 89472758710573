import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JwtModule } from '@auth0/angular-jwt';

import { FullscreenOverlayContainer, OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MaterialModule } from '@modules/material/material.module';
import { SessionModule } from '@modules/session/session.module';
import { SharedModule } from '@modules/shared/shared.module';
import { BlockEmailChangeComponent } from '@pages/block-email-change/block-email-change.component';
import { ChangeEmailComponent } from '@pages/change-email/change-email.component';
import { RegisterFirstStepComponent } from '@pages/register/register-first-step/register-first-step.component';
import { RegisterSecondStepComponent } from '@pages/register/register-second-step/register-second-step.component';
import { SimpleWebRTCService } from '@services/simple-web-rtc.service';
import { WebRTCService } from '@services/webrtc.service';
import { TranslateFakeLoader, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CountdownModule } from 'ngx-countdown';
import { UnauthorizedInterceptor } from 'src/app/interceptors/unauthorized.interceptor';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import localeEnZA from '@angular/common/locales/en-ZA';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ErrorReponseInterceptor } from './interceptors/error-response.interceptor';
import { ErrorRetryInterceptor } from './interceptors/error-retry.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { AcceptInviteComponent } from './pages/accept-invite/accept-invite.component';
import { CompleteDetailsComponent } from './pages/complete-details/complete-details.component';
import { CompleteResetPasswordComponent } from './pages/complete-reset-password/complete-reset-password.component';
import { LandingComponent } from './pages/landing/landing.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { RecoverAccountComponent } from './pages/recover-account/recover-account.component';
import { RegisterComponent } from './pages/register/register.component';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { TelNumberPipe } from './pipes/tel-number.pipe';
import { WorkflowPdfComponent } from './pages/workflow-pdf/workflow-pdf.component';

registerLocaleData(localeEnZA);

// This function is used in the JwtModule.forRoot() call below
// It returns the access token from local storage or session storage
// Subsequent calls to the module's tokenGetter function or getRefreshToken()
// method will return the same value
export function tokenGetter() {
  return localStorage.getItem(environment.accessTokenKey) || sessionStorage.getItem(environment.accessTokenKey);
}

@NgModule({
  declarations: [
    AcceptInviteComponent,
    AppComponent,
    LoginComponent,
    RegisterComponent,
    RegisterFirstStepComponent,
    RegisterSecondStepComponent,
    CompleteDetailsComponent,
    CompleteResetPasswordComponent,
    VerifyEmailComponent,
    LandingComponent,
    TelNumberPipe,
    NotFoundComponent,
    RecoverAccountComponent,
    BlockEmailChangeComponent,
    ChangeEmailComponent,
    PrivacyPolicyComponent,
    WorkflowPdfComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: environment.whitelistedDomains,
        disallowedRoutes: ['/api/auth/register', '/api/auth/login'],
      },
    }),
    GoogleTagManagerModule.forRoot({
      id: 'GTM-NF3RBLC',
      // gtm_resource_path: YOUR_GTM_RESOURCE_PATH
    }),
    OverlayModule,
    HammerModule,
    SessionModule,
    CountdownModule,
    PdfViewerModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en-ZA',
      loader: {
        provide: TranslateLoader,
        useClass: TranslateFakeLoader,
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorReponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorRetryInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    { provide: LOCALE_ID, useValue: 'en-ZA' },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    { provide: WebRTCService, useClass: SimpleWebRTCService },
    TranslateService,
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
