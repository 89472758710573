import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'todayTomorrow',
})
export class TodayTomorrowPipe implements PipeTransform {
  constructor(private readonly date: DatePipe) {} // eslint-disable-line  no-empty, @typescript-eslint/no-empty-function
  transform(value: string): string {
    try {
      return !moment(value).isValid()
        ? value
        : moment().isSame(value, 'date')
        ? 'Today'
        : moment().add(1, 'day').isSame(value, 'date')
        ? 'Tomorrow'
        : this.date.transform(value, 'dd MMM');
    } catch (ex) {
      return this.date.transform(value, 'dd MMM');
    }
  }
}
