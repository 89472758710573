<div class="about-screen" fxLayout="row wrap" fxLayoutAlign="center center">
  <img fxFlex="40%" class="mygc-logo" src="assets/images/myGCLogo_small_icon.svg" alt="myGC Logo" />
  <div fxFlex="100%" class="mygc-header">myGrandCentral</div>
  <div fxFlex="100%" class="version-text">Latest version & release date:</div>
  <div fxFlex="100%" class="version-text version-and-date">
    <span>Version {{ version }}</span>
    on
    <span>{{ date | date : 'mediumDate' }}</span>
  </div>
  <a class="release-notes-button" mat-button mat-dialog-close fxFlex="100%" href="http://updates.goodxcloud.co.za/#mygcapp" target="_blank">
    Read latest release notes
  </a>
  <mat-divider fxFlex="100%"></mat-divider>
  <div fxFlex="100%" class="powered-by">Powered by:</div>
  <div fxFlex="50%">
    <img src="assets/images/goodx-logo.png" alt="GoodX Logo" />
  </div>
  <div fxFlex="50%">
    <img src="assets/images/quant-logo.png" alt="Quant Engineering Logo" />
  </div>
  <button fxFlex="100%" mat-button mat-dialog-close class="close">Close</button>
</div>
