import { Directive, HostListener, Input } from '@angular/core';
import { LoggingService } from '@services/logging.service';

@Directive({
  selector: '[gcLogButton]',
})
export class LogButtonDirective {
  @Input() diaryUuid: string;
  @Input() page: string;
  @Input() context: string;

  constructor(private readonly logger: LoggingService) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    this.logger
      .logExternalBooking(
        this.context ?? '',
        window.location.pathname,
        this.diaryUuid ?? '',
        this.page ?? '',
        'click',
        (event.target as HTMLElement).innerText.trim().replace(/[\p{P}\p{S}]/gu, ''), //replace punctuation and symbols
      )
      .subscribe();
  }
}
