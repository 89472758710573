import { Component, Input } from '@angular/core';
import { Booking } from '@models/booking.model';

@Component({
  selector: 'gc-booking-display-card',
  templateUrl: './booking-display-card.component.html',
  styleUrls: ['./booking-display-card.component.scss'],
})
export class BookingDisplayCardComponent {
  @Input()
  booking: Booking;

  get displayIcon() {
    return this.booking?.type?.icon;
  }
}
