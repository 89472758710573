import { Component, OnInit } from '@angular/core';
import { feedbackTypes } from '@modules/shared/dialogs/feedback-dialog/feedback-dialog.component';
import { FeedbackService } from '@services/feedback.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'gc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private returnUrl: string;

  constructor(
    private readonly activeRoute: ActivatedRoute,
    private readonly feedbackService: FeedbackService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.activeRoute.queryParamMap.subscribe((map) => {
      this.returnUrl = map.get('returnUrl');
      if (this.returnUrl) {
        this.returnUrl = decodeURI(this.returnUrl);
      }
    });
  }

  login() {
    if (this.returnUrl) {
      this.router.navigateByUrl(this.returnUrl);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  feedback() {
    this.feedbackService.feedbackDialog(feedbackTypes.login).subscribe();
  }
}
