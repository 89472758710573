import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'gc-logged-out-header',
  templateUrl: './logged-out-header.component.html',
  styleUrls: ['./logged-out-header.component.scss'],
})
export class LoggedOutHeaderComponent {
  constructor(public router: Router) {}

  onBackClick() {
    this.router.navigate(['/landing']);
  }
}
