import { Notification, NotificationData } from './notification.model';

export class PaymentNotification extends Notification {
  constructor(data: NotificationData) {
    super(data);
    if (this.payment) {
      this.description = `Payment of R${this.payment.paymentAmount} ${this.payment.paid ? 'has been settled' : 'is outstanding'} for ${
        this.payment.treatingDoctor
      }.`;
      this.actionName = this.payment.paid ? 'View' : 'Pay Now';
      this.actionRequired = !this.payment.paid;
    }
  }
}
