import { AbstractControl, ValidationErrors } from '@angular/forms';

export function passwordValidator(control: AbstractControl): ValidationErrors {
  const value: string = control?.value;
  const validationErrors: { number?: boolean; letter?: boolean; password?: boolean } = {};
  if (value && !value.match(/[0-9]+/)) {
    validationErrors.number = true;
  }
  if (value && !value.match(/[a-zA-Z]+/)) {
    validationErrors.letter = true;
  }
  if (value && value.match('password')) {
    validationErrors.password = true;
  }
  if (Object.values(validationErrors).some((v) => v)) {
    return { passwordValidator: validationErrors };
  } else {
    return null;
  }
}
