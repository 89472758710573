import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthGuard } from '@guards/auth.guard';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard extends AuthGuard {
  protected getRedirectDestination(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree {
    // The 'redirectPath' allows for us to manage how to handle paths if the user is not authenticated
    const path = route.routeConfig.data?.redirectPath;
    if (path) {
      return this.router.createUrlTree([path], { queryParams: { returnUrl: state.url } });
    } else {
      // Okay, so we just see if there is an access token stored or if a link was used
      // If there is, we continue as normal, and will pass by login if needed.
      // If there isn't, we just route to the landing page.
      return localStorage.getItem(environment.accessTokenKey) || sessionStorage.session_uuid
        ? this.router.createUrlTree(['/login'], { queryParams: { returnUrl: state.url } })
        : this.router.parseUrl('/landing');
    }
  }
}
