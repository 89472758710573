<div class="login-form">
  <mat-card class="login-card" gdColumn.lt-md="1 / span 4">
    <mat-card-header class="welcome-text">Hello, welcome back!</mat-card-header>
    <mat-card-subtitle class="login-promp">
      <span>
        Log in to continue to your
        <b><i>myGC</i></b>
        account
      </span>
    </mat-card-subtitle>
    <mat-card-content class="login-button">
      <gc-login (loginSuccessful)="login()"></gc-login>
    </mat-card-content>
  </mat-card>
</div>
