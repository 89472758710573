import { OverlayRef } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';

export const SEARCH_TERM = new InjectionToken<string>('SEARCH_TERM');

export interface SearchableRoute {
  readonly phrase: string;
  readonly keywords: string[];
  readonly path: string;
}

export interface SearchItem<T> {
  readonly type: string;
  readonly item: T;
}

export class SearchOverlayRef {
  readonly hasClosed: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly overlayRef: OverlayRef) {}

  close(): void {
    this.overlayRef.dispose();
    this.hasClosed.next(true);
    this.hasClosed.complete();
  }
}
