import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User } from '@models/user.model';
import { TermsAndConditionsDialogComponent } from '@dialogs/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { DisplayService } from '@services/display.service';
import { DoctorService } from '@services/doctor.service';
import { UserService } from '@services/user.service';
import { combineLatest, fromEvent, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AboutDialogComponent } from '@modules/shared/dialogs/about-dialog/about-dialog.component';
import { ConfigLocaleService, ConfigLocation } from '@services/configLocale.service';

const checkStringWithVariableWhitespace = (str: string, expected: string) =>
  str.toLowerCase().trim().replace(/\s+/g, ' ') === expected.toLowerCase().trim().replace(/\s+/g, ' ');

@Component({
  selector: 'gc-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  animations: [
    trigger('infoBoxPopup', [
      transition('false => true', [
        query('.location-info-box:enter', style({ opacity: 0, transform: 'scale(0)', transformOrigin: 'bottom right', top: -21 })),
        query('.corner:enter', style({ opacity: 0, transform: 'scale(0)', transformOrigin: 'bottom right' })),
        group([
          query('.location-info-box:enter', animate('0.2s ease-in-out', style({ opacity: 1, transform: 'scale(1)', top: -36 }))),
          query('.corner:enter', animate('0.2s ease-in-out', style({ opacity: 1, transform: 'scale(1)' }))),
        ]),
      ]),
      transition('true => false', [
        query('.location-info-box:leave', style({ opacity: 1, transform: 'scale(1)', transformOrigin: 'bottom right', top: -36 })),
        query('.corner:leave', style({ opacity: 1, transform: 'scale(1)', transformOrigin: 'bottom right' })),
        group([
          query('.location-info-box:leave', animate('0.2s ease-in-out', style({ opacity: 0, transform: 'scale(0)', top: -21 }))),
          query('.corner:leave', animate('0.2s ease-in-out', style({ opacity: 0, transform: 'scale(0)' }))),
        ]),
      ]),
    ]),
  ],
})
export class LandingComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly NEAR_ME_TEXT = 'near me';
  searchForm: FormGroup;
  autocomplete: google.maps.places.Autocomplete;
  specialities$: Observable<string[]>;
  filteredSpecialities$: Observable<string[]>;
  locationFocused = false;
  coordinates = { latitude: null, longitude: null };
  unsubscribe$ = new Subject<void>();
  scrollTop$: Observable<number>;
  @ViewChild('locationInput') locationInput: ElementRef<HTMLInputElement>;
  hideLength = 100;
  user$: Observable<User>;
  fieldFocused = false;
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly alert: AlertService,
    private readonly router: Router,
    private readonly doctor: DoctorService,
    private readonly vc: ViewportScroller,
    private readonly display: DisplayService,
    private readonly auth: AuthService,
    private readonly user: UserService,
    private readonly dialog: MatDialog,
    private readonly configLocaleService: ConfigLocaleService,
  ) {
    this.scrollTop$ = fromEvent(window, 'scroll').pipe(
      debounceTime(10),
      distinctUntilChanged(),
      map(() => this.vc.getScrollPosition()[1]),
    );
    this.display.isMobile$.subscribe((x) => {
      this.hideLength = x ? 300 : 100;
    });

    this.user$ = this.auth.authenticated$.pipe(switchMap((authed) => (authed ? this.user.profile$ : of(null))));
  }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      searchTerm: [''],
      location: ['', { validators: Validators.required, updateOn: 'blur' }],
      speciality: [''],
    });

    this.specialities$ = this.doctor.getSpecialities$();
    this.filteredSpecialities$ = combineLatest([
      this.searchForm.get('speciality').valueChanges.pipe(startWith('')),
      this.specialities$,
    ]).pipe(map(([val, specialities]) => specialities?.filter((s) => s?.toLowerCase().includes(val.toLowerCase()))));

    this.searchForm
      .get('location')
      .valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe$))
      .subscribe((location: string) => {
        if (checkStringWithVariableWhitespace(location, this.NEAR_ME_TEXT)) {
          this.requestCoordinates();
        }
      });
  }

  ngAfterViewInit() {
    this.autocomplete = new google.maps.places.Autocomplete(this.locationInput.nativeElement, {
      types: ['geocode'],
      fields: ['geometry'],
    });
    this.configLocaleService.getValueByKey$(['address_autocomplete']).subscribe((config) => {
      this.autocomplete.setComponentRestrictions({
        country: [config.address_autocomplete],
      });
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  async getCoordinates(): Promise<Coordinates> {
    const position = await new Promise<Position>((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, { enableHighAccuracy: true }),
    );
    return position.coords;
  }

  async requestCoordinates() {
    try {
      const { latitude, longitude } = await this.getCoordinates();
      this.coordinates = { latitude, longitude };
    } catch {
      this.alert.snackbar('Please allow location permissions');
    }
  }

  async search() {
    if (this.searchForm.invalid) {
      this.searchForm.value.location = 'near me';
    }
    const { location, searchTerm, speciality } = this.searchForm.value;
    if (checkStringWithVariableWhitespace(location, this.NEAR_ME_TEXT)) {
      if (!this.coordinates.latitude || !this.coordinates.longitude) {
        try {
          const { latitude, longitude } = await this.getCoordinates();
          this.navigateToDoctorList(latitude, longitude, searchTerm, speciality);
        } catch {
          // Pretoria is the default location
          this.configLocaleService.getValueByKey$(['location_json']).subscribe((config) => {
            const { latitude, longitude } = JSON.parse(config.location_json) as ConfigLocation;
            this.navigateToDoctorList(latitude, longitude, searchTerm, speciality);
          });
        }
      } else {
        const { latitude, longitude } = this.coordinates;
        this.navigateToDoctorList(latitude, longitude, searchTerm, speciality);
      }
    } else {
      const place = this.autocomplete.getPlace();
      if (place) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        this.navigateToDoctorList(latitude, longitude, searchTerm, speciality);
      } else {
        this.alert.snackbar('Please select a valid location or use your current location');
      }
    }
  }

  navigateToDoctorList(latitude: number, longitude: number, searchTerm: string, speciality: string) {
    this.router.navigate(['doctors'], {
      queryParams: { latitude, longitude, searchTerm, speciality },
    });
  }

  onFocus(field: string) {
    this.fieldFocused = true;
    switch (field) {
      case 'location':
        this.locationFocused = true;
        break;
    }
  }

  onBlur(field: string) {
    this.fieldFocused = false;
    switch (field) {
      case 'location':
        this.locationFocused = false;
        break;
    }
  }

  showPrivacyPolicy() {
    this.dialog.open(TermsAndConditionsDialogComponent);
  }

  showAboutUs() {
    this.alert.openDialog$(AboutDialogComponent, { panelClass: 'gc-about-dialog' });
  }
}
