import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const BEARER_PREFIX = 'Bearer';
const AUTH_HEADER = 'Authorization';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  refreshInProgress = false;
  refreshSubject$ = new BehaviorSubject<any>(null);
  constructor(private readonly auth: AuthService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: unknown) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 && !req.url.match(/\/api\/auth/) && !req.url.match(/google.com/)) {
            if (this.refreshInProgress) {
              return this.refreshSubject$.pipe(
                filter((result) => result !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthHeaders(req))),
              );
            } else {
              this.refreshInProgress = true;
              this.refreshSubject$.next(null);
              return this.auth.refresh().pipe(
                catchError(() => {
                  this.auth.authenticated$.next(false);
                  [environment.accessTokenKey, environment.refreshTokenKey].forEach((k) => {
                    localStorage.removeItem(k);
                    sessionStorage.removeItem(k);
                  });
                  return of(false);
                }),
                switchMap((token) => {
                  this.refreshSubject$.next(!!token);
                  if (!token) {
                    [environment.accessTokenKey, environment.refreshTokenKey].forEach((k) => {
                      localStorage.removeItem(k);
                      sessionStorage.removeItem(k);
                    });
                    return throwError(err);
                  }
                  return next.handle(this.addAuthHeaders(req));
                }),
                finalize(() => (this.refreshInProgress = false)),
              );
            }
          } else {
            return throwError(err);
          }
        }
      }),
    );
  }

  private addAuthHeaders(req: HttpRequest<any>) {
    const token = localStorage.getItem(environment.accessTokenKey) || sessionStorage.getItem(environment.accessTokenKey);
    return req.clone({
      setHeaders: token ? { [AUTH_HEADER]: `${BEARER_PREFIX} ${token}` } : undefined,
    });
  }
}
