<span class="payment text-style">
  <div fxLayout="row" fxLayoutAlign="start">
    <mat-icon [color]="payment.paid ? 'accent' : 'warn'">
      {{ payment.paid ? 'check_circle' : 'error_outline' }}
    </mat-icon>
    <div fxLayout="column" fxLayoutAlign="space-evenly">
      <span class="date">{{ payment.invoiceDate?.toDate() | localizedDate : 'fullDate' }}</span>
      <span class="description" [class.error]="!payment.paid">
        {{ payment.paymentAmount | currency : 'ZAR' : 'symbol-narrow' }} - {{ payment.treatingDoctor }}
      </span>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end">
    <button
      mat-flat-button
      [routerLink]="['/payments']"
      [queryParams]="{ hash: payment.hash }"
      [color]="payment.paid ? 'accent' : 'primary'"
      type="button"
    >
      {{ payment.paid ? 'View Invoice' : 'Pay Now' }}
    </button>
  </div>
</span>
