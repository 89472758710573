import { Component, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { Notification } from '@models/notifications/notification.model';
import { DisplayService } from '@services/display.service';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gc-swipe-notifications',
  templateUrl: './swipe-notifications.component.html',
  styleUrls: ['./swipe-notifications.component.scss'],
})
export class SwipeNotificationsComponent implements OnInit, OnDestroy {
  notifications: Notification[] = [];
  @ViewChildren('swipeCard') cardRefs: QueryList<ElementRef>;

  shiftRequired: boolean; // state variable that indicates whether we need to remove the top card of the stack
  isMobile: boolean;

  transitionInProgress = false; // state variable that indicates whether a transition is currently on-going
  moveOutTriggerWidth = 200; // how far a card must travel along the x axis before it has 'left' the screen.
  moveOutTriggerVelocity = 0.5; // how fast a card must travel along the x axis before it has 'left' the screen.
  baseScale = 0.95; // scale percentage
  baseTranslate = 0.75; // em
  maxRenderedCardCount = 4;

  private readonly unsubscribe$ = new Subject<void>();
  isLoading: boolean;

  constructor(
    private readonly display: DisplayService,
    private readonly renderer: Renderer2,
    private readonly notification: NotificationService,
  ) {}

  get topCard() {
    return this.cardRefs.first;
  }

  dismissCard(event, notification) {
    event.preventDefault();
    if (!this.notifications.length) {
      return false;
    }
    const transformString = `translate(-${2 * this.moveOutTriggerWidth}px, -100px) rotate(-30deg)`;
    this.renderer.setStyle(this.topCard.nativeElement, 'transform', transformString);
    this.shiftRequired = true;
    this.transitionInProgress = true;
    event.stopPropagation();
    this.notification.markAsRead$(notification).pipe(take(1)).subscribe();
  }

  handleShift() {
    this.transitionInProgress = false;
    if (this.shiftRequired) {
      this.shiftRequired = false;
      this.notification.markAsRead$(this.notifications.shift()).pipe(take(1)).subscribe();
    }
  }

  handlePan(event) {
    if (event.deltaX === 0 || !this.notifications.length) {
      return;
    }

    if (this.transitionInProgress) {
      this.handleShift();
    }

    this.renderer.addClass(this.topCard.nativeElement, 'moving');
    const transformString = `translate(${event.deltaX}px, ${event.deltaY}px)`;
    this.renderer.setStyle(this.topCard.nativeElement, 'transform', transformString);

    this.shiftRequired = true;
  }

  handlePanEnd(event) {
    if (!this.notifications.length) {
      return;
    }
    this.renderer.removeClass(this.topCard.nativeElement, 'moving');
    const smallDeltaX = Math.abs(event.deltaX) < this.moveOutTriggerWidth;
    const smallVelocityX = Math.abs(event.velocityX) < this.moveOutTriggerVelocity;
    const keep = smallDeltaX || smallVelocityX;
    if (keep) {
      this.renderer.setStyle(this.topCard.nativeElement, 'transform', '');
      this.shiftRequired = false;
    } else {
      const endX = Math.max(Math.abs(event.velocityX) * this.moveOutTriggerWidth, this.moveOutTriggerWidth);
      const toX = event.deltaX > 0 ? endX : -endX;
      const endY = Math.abs(event.velocityY) * this.moveOutTriggerWidth;
      const toY = event.deltaY > 0 ? endY : -endY;
      const transformString = `translate(${toX}px, ${toY}px)`;
      this.renderer.setStyle(this.topCard.nativeElement, 'transform', transformString);
      this.shiftRequired = true;
    }
    this.transitionInProgress = true;
  }

  getCardStyle(i: number) {
    if (0 <= i && i <= this.maxRenderedCardCount) {
      const scaleDirection = this.isMobile ? 'Y' : 'X';
      const translateDirection = this.isMobile ? 'X' : 'Y';
      const scaleString = `scale${scaleDirection}(${this.baseScale ** i})`;
      const translateString = `translate${translateDirection}(${this.baseTranslate * i}em)`;
      const transformString = `${scaleString} ${translateString}`;
      const style = { zIndex: -i, transform: transformString };
      return style;
    } else {
      const err = `Invalid card number: ${i}. Parameter must be between 0 and ${this.maxRenderedCardCount}`;
      throw new Error(err);
    }
  }

  doNotification(notification: Notification) {
    this.notification.notificationAction(notification);
  }

  ngOnInit() {
    this.isLoading = true;
    // Using the setTimeout to prevent the loading spinner from showing for too long.
    // Loading things should not prohibit the user from using the app.
    setTimeout(() => {
      if (this.isLoading) {
        this.unsubscribe$.next();
        this.isLoading = false;
      }
    }, 5000);
    this.notification.notifications$.pipe(takeUntil(this.unsubscribe$)).subscribe((notifs) => {
      this.isLoading = false;
      this.notifications = notifs;
    });
    this.display.isMobile$.pipe(takeUntil(this.unsubscribe$)).subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }
}
