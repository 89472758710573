import * as moment from 'moment';
import { Notification, NotificationData } from './notification.model';

export class MedicalHistoryNotification extends Notification {
  constructor(data: NotificationData) {
    super(data);
    this.actionName = 'View Access';
    this.actionRequired = !this.readDate;
    const requestDate = moment(data?.medicalHistoryRequest?.requested_timestamp);
    this.description = [
      `${data?.medicalHistoryRequest?.doctor_name ?? 'A practitioner'} has requested your Medical History`,
      ` on ${requestDate.format('HH:mm on dddd, D MMMM')}`,
    ].join(' ');
  }
}
