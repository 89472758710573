import { ClinicalDocument } from './clinical-document.model';

export interface PatientAttachmentData {
  user_id: number;
  title?: string;
  filedata?: PatientAttachmentFileData;
  date: string;
}

export interface PatientAttachmentFileData {
  name: string;
  data: string;
  mime_type: string;
}

export class PatientAttachment extends ClinicalDocument {
  type = 'attachment';
  filedata: PatientAttachmentFileData;
  public get label() {
    return `Attachment: ${this.title}`;
  }

  constructor(data: PatientAttachmentData) {
    super(data);
    this.filedata = data.filedata;
  }
}
