import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorReponseInterceptor implements HttpInterceptor {
  /*
   * Intercept HTTP requests and resolve the JSON error object returned
   * by the backend.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((err: unknown) => {
        if (err instanceof HttpErrorResponse) {
          return throwError(err.error);
        } else {
          return throwError(err);
        }
      }),
    );
  }
}
