<mat-form-field #formField>
  <mat-label *ngIf="label">{{ label }} {{ required ? '*' : '' }}</mat-label>
  <input
    matInput
    [value]="value"
    [type]="type"
    [autocomplete]="autocomplete"
    [name]="name"
    (input)="onInputChange($event)"
    (blur)="onInputBlur()"
    [readonly]="readonly"
    [disabled]="disabled"
  />
  <mat-icon
    matSuffix
    *ngIf="showStatusIcon && (touched || dirty)"
    [svgIcon]="showError ? 'close-circle' : 'check-circle'"
    [color]="showError ? 'warn' : 'accent'"
  ></mat-icon>
  <mat-icon
    matSuffix
    *ngIf="!(touched || dirty) && showInfo"
    (click)="openInfoDialog()"
    class="question"
    svgIcon="help-circle-outline"
  ></mat-icon>
  <mat-error *ngIf="error && showError">
    {{ errorText }}
  </mat-error>
  <mat-hint *ngIf="hint">
    {{ hint }}
  </mat-hint>
</mat-form-field>
