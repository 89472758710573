import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distance',
})
export class DistancePipe implements PipeTransform {
  transform(value: number, precision = 1): string {
    if (!value && value !== 0) {
      return 'Unknown';
    }
    return `${value.toFixed(precision)}km`;
  }
}
