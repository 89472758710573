<div class="register-second-step" fxLayout="row">
  <mat-card class="have-account" fxHide.lt-md>
    <div class="img-desktop" fxLayoutAlign="center center">
      <img src="assets/images/myGCLogo_default-white.svg" alt="myGC Logo" />
    </div>
    <div>
      <img src="assets/images/PIN_for-dark-bg.svg" alt="myGC Logo" />
    </div>
  </mat-card>
  <mat-card class="verify-number">
    <mat-card-header fxLayout="column" fxLayoutAlign="center stretch">
      <span fxHide.lt-md>Step 2 of 2:</span>
      Verify your number
    </mat-card-header>
    <div *ngIf="!mobileNumberVerified" class="otp-card" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
      <div class="image-mobile" fxLayoutAlign="center center" fxHide.gt-sm>
        <img src="assets/images/PIN_for-light-bg.svg" alt="myGC Logo" />
      </div>
      <p class="otp-label">Please enter the PIN sent to verify your mobile number</p>
      <form [formGroup]="mobileNumberForm" fxFill>
        <mat-form-field fxFill>
          <mat-label>Your mobile number</mat-label>
          <input matInput type="text" name="number" formControlName="number" />
        </mat-form-field>
        <mat-form-field fxFill>
          <mat-label>One Time Pin (OTP)</mat-label>
          <input
            gcAutofocus
            matInput
            type="text"
            name="otp"
            class="uppercase"
            autocomplete="one-time-pin"
            [formControl]="otpControl"
            maxlength="4"
          />
        </mat-form-field>
      </form>
      <button class="resend-btn" color="primary" mat-raised-button (click)="resendOtp()">
        <span *ngIf="!displayTimer">Resend PIN</span>
        <countdown *ngIf="displayTimer" #countdown [config]="{ leftTime: 30 }" (event)="enableButton($event)"></countdown>
      </button>
    </div>
    <gc-terms-and-conditions (acceptTerms)="termsAndConditions($event)" fxLayoutAlign="end end" fxHide.lt-md></gc-terms-and-conditions>
    <div class="footer" fxLayout="row" fxLayoutAlign="space-between" fxHide.lt-md>
      <button mat-raised-button color="accent" type="button" (click)="logout()">
        <mat-icon svgIcon="lock"></mat-icon>
        Logout
      </button>
      <button mat-raised-button type="button" (click)="submit()" color="primary" [disabled]="invalid">Finish sign up</button>
    </div>
  </mat-card>
</div>
<div class="footer" fxLayout="column" fxHide.gt-sm>
  <gc-terms-and-conditions class="terms-box" (acceptTerms)="termsAndConditions($event)"></gc-terms-and-conditions>
  <div fxLayout="row" fxLayoutAlign="space-between" class="footer-btn" fxFill>
    <button mat-raised-button color="accent" type="button" (click)="logout()">
      <mat-icon svgIcon="lock"></mat-icon>
      Logout
    </button>
    <div>
      <button class="finish-btn" mat-raised-button color="primary" type="button" (click)="submit()" [disabled]="invalid">
        Finish sign up
      </button>
    </div>
  </div>
</div>
