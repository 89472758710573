import { Component, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Payment, ThemeData } from '@models/account.model';
import { dialogMessage } from '@models/dialog-communication.model';
import { LoggingService } from '@services/logging.service';
import { FeedbackService } from '@services/feedback.service';
import { calculateFontColour } from '@utilities/colour-utils';
import { feedbackTypes } from '@modules/shared/dialogs/feedback-dialog/feedback-dialog.component';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'gc-view-payment',
  templateUrl: './view-payment.component.html',
  styleUrls: ['./view-payment.component.scss'],
})
export class ViewPaymentComponent {
  @Input() payment: Payment;
  @Input() url: string;
  @Input() theme: ThemeData;
  payAllInvoices: boolean;
  constructor(
    public dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly sanitizer: DomSanitizer,
    private readonly logger: LoggingService,
    private readonly feedback: FeedbackService,
  ) {}

  onlyOnePaymentOption(payment) {
    const payments: string[] = [payment.ozowPaymentUrl, payment.peachpayPaymentUrl, payment.fnbPaymentUrl, payment.stripePaymentUrl];
    const paymentOptionsLength = payments.filter((p) => p !== null).length;
    // True if the old payment model is used or if there is only one payment option.
    if ((paymentOptionsLength === 0 && payment.paymentUrl) || paymentOptionsLength === 1) {
      return true;
    } else {
      return false;
    }
  }

  // Open direct payment lnik if there is only one option
  openPayment(payment) {
    this.logger.addToJourney('action/open-payment-iframe');
    const paymentUrlBuild = new URL(payment.paymentUrl);
    paymentUrlBuild.searchParams.append('payment_notify_url', window.location.origin + this.url);
    const paymentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(paymentUrlBuild.href);
    // Stripe doesnt support showing in iframe
    if (payment.stripePaymentUrl) {
      window.location.replace(this.payment.stripePaymentUrl);
    } else {
      const dialogRef = this.dialog.open(PaymentDialogComponent, {
        width: '650px',
        data: { paymentUrl },
      });

      const handleListen = (event: MessageEvent) => {
        if (event.origin === window.origin && event.data === dialogMessage.close) {
          dialogRef.close();
        }
      };

      window?.addEventListener('message', handleListen.bind(this));

      //After the iframe is closed
      dialogRef
        ?.afterClosed()
        .pipe(switchMap(() => this.feedback.feedbackDialog(feedbackTypes.payment)))
        .subscribe(() => {
          window?.location.reload();
        });
    }
  }

  getBackground() {
    let firstColour = 'var(--primary-color)';
    if (this.theme?.theme_colours?.primary) {
      firstColour = this.theme.theme_colours.primary;
    }
    return `${firstColour}`;
  }

  getFontColour(): string {
    if (this.theme?.theme_colours?.primary) {
      return calculateFontColour(this.theme?.theme_colours?.primary);
    }
    return '#FFF';
  }

  hasTheme(): boolean {
    return !!this.theme?.theme_colours?.primary && !!this.theme?.theme_colours?.accent;
  }

  selectPayment(payment: Payment) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        type: null,
        hash: payment.hash,
      },
      // preserve the existing query params in the route
      queryParamsHandling: 'merge',
      // do not trigger navigation
      skipLocationChange: true,
    });
  }

  backPayment() {
    this.payment = null;
    this.payAllInvoices = false;
  }

  // Opens the next screen to select the payment option
  makePayment() {
    this.payAllInvoices = true;
  }
}

@Component({
  selector: 'gc-payment-dialog',
  templateUrl: 'payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss'],
})
export class PaymentDialogComponent {
  result = false;
  constructor(
    public dialogRef: MatDialogRef<PaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Payment,
    private readonly feedback: FeedbackService,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onFeedbackClick(): void {
    this.feedback.feedbackDialog(feedbackTypes.payment).subscribe();
  }
}
