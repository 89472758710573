import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AlertService } from '@services/alert.service';
export interface BetaTester {
  email?: string;
  user_id?: number;
  name?: string;
  created_timestamp?: string;
  active?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private readonly http: HttpClient, private readonly alert: AlertService) {}

  deleteAccount(password: string) {
    return this.http.patch('/api/profile/delete', { password }).pipe(
      catchError((err: unknown) => {
        this.alert.handleErrorDialog$(err, 'Unable to delete account');
        return of(null);
      }),
    );
  }

  recoverAccount(code: string) {
    return this.http.patch('/api/profile/recover', { code }).pipe(
      catchError((err: unknown) => {
        this.alert.handleErrorDialog$(err, 'Unable to delete account');
        return of(null);
      }),
    );
  }

  getBetaTester$(userId: number, email: string): Observable<BetaTester> {
    return this.http.get<BetaTester>('/api/profile/beta-tester', { params: { userId, email }, observe: 'response' }).pipe(
      map((res: HttpResponse<BetaTester>) => res.body),
      catchError((error: unknown) => {
        const err = error as HttpErrorResponse;
        throw new Error(err.message);
      }),
    );
  }

  postBetaTester$(betaTesterData: BetaTester): Observable<BetaTester> {
    return this.http.post<BetaTester>('/api/profile/beta-tester', betaTesterData, { observe: 'response' }).pipe(
      map((res: HttpResponse<BetaTester>) => res.body),
      catchError((error: unknown) => {
        const err = error as HttpErrorResponse;
        throw new Error(err.message);
      }),
    );
  }
}
