import { Notification, NotificationData } from './notification.model';

export class ConsentFormNotification extends Notification {
  constructor(data: NotificationData) {
    super(data);
    const haveResponse: boolean | string | null = data.consentForm ? data.consentForm.signed : this.readDate;
    this.description = `Consent Form from ${data?.consentForm?.doctor}`;
    this.actionName = !!haveResponse ? 'Completed' : 'To Complete';
    this.actionRequired = !this.readDate;
  }
}
