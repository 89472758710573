import { FamilyRole, FAMILY_ROLES } from '@constants';
import { User, UserData } from './user.model';

export interface FamilyMemberData extends UserData {
  role: string;
  family_id: number;
}

export class FamilyMember extends User {
  readonly role: string;
  readonly familyId: number;
  constructor(data: FamilyMemberData) {
    super(data);
    this.role = data.role;
    this.familyId = data.family_id;
  }

  get fullRole(): FamilyRole {
    return FAMILY_ROLES.find((r) => r.name === this.role);
  }

  get isAdmin() {
    return ['owner', 'admin'].includes(this.role);
  }

  get rawData(): FamilyMemberData {
    return {
      ...super.rawData,
      family_id: this.familyId,
      role: this.role,
    };
  }
}
