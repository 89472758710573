import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { passwordValidator } from '@validators/password.validator';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'gc-complete-reset-password',
  templateUrl: './complete-reset-password.component.html',
  styleUrls: ['./complete-reset-password.component.scss'],
})
export class CompleteResetPasswordComponent implements OnInit {
  passwordForm: FormGroup;
  showPassword = false;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly formBuilder: FormBuilder,
    private readonly auth: AuthService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), passwordValidator])],
      code: [''],
    });
    this.route.queryParamMap
      .pipe(
        take(1),
        filter((params) => !!params.get('code')),
      )
      .subscribe((params) => {
        this.passwordForm.patchValue({ code: params.get('code') });
      });
  }

  get passwordErrors(): { number: boolean; letter: boolean; password: boolean } {
    return this.passwordForm.get('password').getError('passwordValidator');
  }

  submit() {
    const { code, password } = this.passwordForm.value;
    this.auth
      .verifyPasswordReset(code, password)
      .pipe(filter((result) => !!result?.success))
      .subscribe((res) => {
        this.alertService.snackbar(res?.message);
        this.router.navigate(['/login']);
      });
  }
}
