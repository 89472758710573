import { AfterViewInit, Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchOverlayRef, SEARCH_TERM } from '@modules/session/models/search.model';
import { SearchService } from '@modules/session/services/search.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, toArray } from 'rxjs/operators';

@Component({
  selector: 'gc-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements AfterViewInit, OnInit {
  private readonly searchTermSubject = new Subject<string>();
  public searchBar = new FormControl();

  readonly results$ = this.searchTermSubject.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap((searchTerm) => this.searchApp(searchTerm)),
  );

  constructor(
    private readonly searchService: SearchService,
    public overlayRef: SearchOverlayRef,
    @Inject(SEARCH_TERM) public searchTerm = '',
  ) {}

  ngOnInit() {
    this.searchBar.valueChanges.subscribe((term) => this.searchTermSubject.next(term));
  }

  ngAfterViewInit() {
    this.searchBar.setValue(this.searchTerm);
    this.searchTermSubject.next(this.searchTerm);
  }

  @HostListener('document:keydown', ['$event']) // @ts-ignore: https://github.com/Microsoft/TypeScript/issues/13120
  private handleKeydown(event: KeyboardEvent) {
    if (['Escape', 'GoBack'].includes(event.key)) {
      this.closeOverlay();
    }
  }

  public closeOverlay() {
    this.overlayRef.close();
  }

  public searchApp(searchTerm: string) {
    return this.searchService.getResults$(searchTerm).pipe(toArray());
  }
}
