import { ClinicalDocument } from './clinical-document.model';

export interface ClaimedPDFData {
  uuid: string;
  event_pdf: string;
  user_id: number;
  patient_cellnr?: string;
  patient_email?: string;
  patient_name: string;
  patient_surname: string;
  report_description?: string;
  report_name?: string;
  type: string;
  treating_doctor: {
    fullname: string;
    practice?: string;
    qualification?: string;
    samdc?: string;
  };
  create_date?: string;
}

export class PdfDocument extends ClinicalDocument {
  public readonly type = 'documents';

  public get label() {
    return `Document from ${this.doctor}`;
  }
}
