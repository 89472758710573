import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { LoggingService } from '@services/logging.service';

@Component({
  selector: 'gc-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  constructor(public location: Location, private readonly logger: LoggingService) {
    this.logger.addToJourney('/not-found');
  }
}
