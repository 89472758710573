import { Location } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { CountdownComponent, CountdownEvent } from 'ngx-countdown';
import { concat, of } from 'rxjs';
import { first, last, take, tap } from 'rxjs/operators';

@Component({
  selector: 'gc-register-second-step',
  templateUrl: './register-second-step.component.html',
  styleUrls: ['./register-second-step.component.scss'],
})
export class RegisterSecondStepComponent implements OnInit, AfterViewInit {
  otpControl: FormControl;
  acceptTerms = false;
  mobileNumberVerified = false;
  @Output() stepComplete = new EventEmitter<void>();
  @ViewChild('countdown', { static: false }) private readonly countdown: CountdownComponent;
  mobileNumberForm: FormGroup;
  displayTimer = false;

  constructor(
    public location: Location,
    private readonly formBuilder: FormBuilder,
    private readonly alert: AlertService,
    private readonly user: UserService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    public auth: AuthService,
  ) {}

  get invalid(): boolean {
    const termsAccepted = this.acceptTerms;
    return !termsAccepted || (this.otpControl.invalid && !this.mobileNumberVerified);
  }

  ngOnInit() {
    this.mobileNumberForm = this.fb.group({
      number: [''],
    });
    this.otpControl = this.formBuilder.control('', Validators.required);
    this.user
      .getUserProfile()
      .pipe(take(1))
      .subscribe((profile) => {
        this.mobileNumberForm.get('number').setValue(profile.cellnr);
      });

    this.user.profile$.pipe(first((user) => !!user)).subscribe((user) => {
      if (user.cellnrVerified) {
        this.stepComplete.emit();
      }
    });
  }

  ngAfterViewInit() {
    this.countdown?.restart();
  }

  enableButton($event: CountdownEvent) {
    if ($event.action === 'done') {
      this.displayTimer = false;
    }
  }

  resendOtp() {
    this.displayTimer = true;
    this.auth.requestMobileVerification$(this.mobileNumberForm.get('number').value).subscribe((res) => {
      if (res.success) {
        this.countdown?.restart();
      }
    });
  }

  termsAndConditions(checked: boolean) {
    this.acceptTerms = checked;
  }

  verifyMobile() {
    const otp = this.otpControl.value.toUpperCase();
    return this.auth.verifyMobile(otp);
  }

  submit() {
    const verifyMobile$ = this.mobileNumberVerified ? of(null) : this.verifyMobile();
    const acceptTerms$ = this.user.updateUserProfile({ terms_accepted: true });
    concat(verifyMobile$, acceptTerms$)
      .pipe(last())
      .subscribe(
        (res) => {
          if (res) {
            this.stepComplete.emit();
          }
        },
        (err: unknown) => {
          this.alert.handleErrorDialog$(err, 'Mobile number could not be verified');
        },
      );
  }

  logout() {
    this.alert
      .messageDialog$('Confirm log out', 'Are you sure you want to log out?', 'error', true)
      .pipe(
        tap((data) => {
          if (data) {
            this.auth.logout().subscribe(() => {
              this.router.navigate(['/landing']);
            });
          }
        }),
      )
      .subscribe();
  }
}
