import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Booking } from '@models/booking.model';
import { BookingService } from '@services/booking.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'gc-confirm-booking',
  templateUrl: './confirm-booking.component.html',
  styleUrls: ['./confirm-booking.component.scss'],
})
export class ConfirmBookingComponent implements OnInit {
  booking: Booking;
  error = false;
  constructor(private readonly route: ActivatedRoute, private readonly bookingService: BookingService) {}

  ngOnInit(): void {
    // Get booking_uuid from route params
    const bookingUuid = this.route.snapshot.paramMap.get('booking_uuid');
    const identifier = this.route.snapshot.data.identifier;

    this.bookingService
      .getBooking$(bookingUuid, identifier)
      .pipe(
        switchMap((booking) => {
          this.booking = booking;
          return this.bookingService.confirmBooking$(bookingUuid);
        }),
      )
      .subscribe(
        () => {
          // Confirmation successful
        },
        (err: unknown) => {
          console.error('Error confirming booking', err);
          this.error = true;
        },
      );
  }
}
