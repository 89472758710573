<h1 mat-dialog-title *ngIf="data.message" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px">
  <mat-icon *ngIf="data.tag" [class]="data.tag" [svgIcon]="dialogIcon"></mat-icon>
  <span fxFlex="grow">{{ data.message }}</span>
</h1>
<span mat-dialog-content *ngIf="data.detail" [innerHTML]="data.detail"></span>
<div mat-dialog-actions align="end" *ngIf="!data.confirmation">
  <button mat-button mat-dialog-close [class]="data.tag" [color]="confirmColour" (click)="okayClick()">{{ confirmText }}</button>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-evenly" *ngIf="data.confirmation">
  <button mat-button [mat-dialog-close]="false" *ngIf="!data.hideCancel" class="cancel-button" [class]="data.tag" [color]="cancelColour">
    {{ cancelText }}
  </button>
  <button mat-button [mat-dialog-close]="true" [class]="data.tag" [color]="confirmColour">{{ confirmText }}</button>
</div>
