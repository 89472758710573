import * as moment from 'moment';
import { Notification, NotificationData } from './notification.model';

export class TelemedNotification extends Notification {
  constructor(data: NotificationData) {
    super(data);
    const date = moment(this.sentDate, 'YYYY-MM-DD HH:mm:ss');
    const now = moment();
    const hours = date.diff(now, 'hours');
    if (hours < 0) {
      this.description = `Telemed booking of ${this.sentDate}.`;
    } else if (hours <= 12) {
      const mins = date.diff(now, 'minutes');
      this.description = `Telemed booking in ${hours} hours and ${mins % 60} minutes.`;
    } else if (hours <= 24) {
      this.description = 'Telemed booking for tomorrow.';
    } else {
      const days = date.startOf('day').diff(now.startOf('day'), 'days');
      this.description = `Telemed booking in ${days} days.`;
    }
    this.actionName = 'View';
    this.actionRequired = hours > 0;
  }
}
