import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'gc-contact',
  templateUrl: './contact-button.component.html',
  styleUrls: ['./contact-button.component.scss'],
})
export class ContactButtonComponent {
  @Input() color = 'primary';
  @Input() icon: string;
  @Input() title: string;
  @Input() href: string;
  @Input() disabled: boolean;
  @Input() isButton = false;

  @HostListener('click')
  onClick() {
    if (!this.isButton && this.href) {
      window.open(this.href, '_blank');
    }
  }
}
