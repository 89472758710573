import { Notification, NotificationData } from './notification.model';

export class DocumentNotification extends Notification {
  constructor(data: NotificationData) {
    super(data);
    switch (this.subtype) {
      case 'referral-letter':
        this.description = `View referral letter from ${data?.document?.doctor}`;
        this.actionName = 'View referral letter';
        break;
      case 'sicknote':
        this.description = `View sick note from ${data?.document?.doctor}`;
        this.actionName = 'View sick note';
        break;
      default:
        this.description = `View ${this.subtype} from ${data?.document?.doctor}`;
        this.actionName = `View ${this.subtype}`;
        break;
    }
    this.actionRequired = !this.readDate;
  }
}
