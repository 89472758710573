import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AlertService } from '@services/alert.service';
import { LoggingService } from '@services/logging.service';
import { PackageService } from '@services/package.service';
import { UserService } from '@services/user.service';
import { Observable, of } from 'rxjs';
import { pluck, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IdResolverService implements Resolve<string> {
  constructor(
    private readonly user: UserService,
    private readonly alert: AlertService,
    private readonly packageService: PackageService,
    private readonly logger: LoggingService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<string> {
    const resolverId$ = this.user.profile$.pipe(take(1));
    const isKiosk = route.data?.kiosk ?? false;

    if (route.data && isKiosk && route.params?.patient_cellnr) {
      this.packageService.identifier = route.params.patient_cellnr;
    } else if (route.data && isKiosk) {
      this.packageService.identifier = null;
    }

    return resolverId$.pipe(
      switchMap((user) => {
        if (user) {
          if (user.cellnr) {
            return of(user.cellnr);
          } else if (user.idnumber && !isKiosk) {
            return of(user.idnumber);
          }
        } else {
          // Usecellphone = true, so we want to validate with cellnumber
          return this.idDialog$('Cellphone no or Surname or ID no');
        }
      }),
    );
  }

  idDialog$(dialogLabel: string = 'ID Number or Surname'): Observable<string> {
    if (!!this.packageService.identifier) {
      this.logger.addToJourney(`package identifier already set: ${this.packageService.identifier}`);
      return of(this.packageService.identifier);
    }
    const config = { backdropClass: 'blur', hasBackdrop: true, disableClose: true };
    const dialog = {
      title: 'Verify your Identity',
      tag: 'payment',
      submitText: 'Continue',
      hideCancel: true,
      questions: [{ label: dialogLabel, required: true, key: 'identifier' }],
    };
    const idnumber$ = this.alert.inputDialog$(dialog, config).pipe(pluck('identifier'));
    idnumber$.subscribe((id) => {
      this.logger.addToJourney(`package identifier set: ${id}`);
      this.packageService.identifier = id;
    });
    return idnumber$;
  }
}
