import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';

import { AboutDialogComponent } from '@dialogs/about-dialog/about-dialog.component';

import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { DisplayService } from '@services/display.service';
import { FeedbackService } from '@services/feedback.service';
import { NotificationService } from '@services/notification.service';

import { ActivationStart, Router } from '@angular/router';
import { feedbackTypes } from '@modules/shared/dialogs/feedback-dialog/feedback-dialog.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

interface ToolbarLink {
  label: string;
  icon: string;
  path?: string;
  dialog?: string;
  amountBadge?: Observable<number>;
}

@Component({
  selector: 'gc-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [style({ transform: 'translateX(-100%)' }), animate('200ms ease-in', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))]),
    ]),
  ],
})
export class SidebarNavComponent implements OnInit, OnDestroy {
  desktopImgSrc = 'assets/images/myGCLogo_vertical.svg';
  mobileImgSrc = 'assets/images/myGCLogo_default.svg';
  loggedIn$: Observable<boolean>;
  toolbarLinks$: Observable<ToolbarLink[]>;
  @HostBinding('class.visible') visible = true;
  isMobile$: BehaviorSubject<boolean>;
  unsubscribe$ = new Subject<void>();

  bottomLinks = [
    { label: 'Feedback', icon: 'send', dialog: 'feedback' },
    { label: 'About <span class="mygc-text">myGC</span>', icon: 'information', dialog: 'about' },
  ];
  private mobile: boolean;

  constructor(
    private readonly alert: AlertService,
    private readonly auth: AuthService,
    private readonly display: DisplayService,
    private readonly router: Router,
    private readonly notification: NotificationService,
    private readonly feedback: FeedbackService,
  ) {}

  ngOnInit() {
    // create an observable for whether or not the user is authenticated
    this.loggedIn$ = this.auth.authenticated$;
    this.isMobile$ = this.display.isMobile$;
    // create consts for links in the sidebar
    const loggedInLinks = [
      { label: 'Dashboard', icon: 'home', path: '/dashboard' },
      { label: 'Notifications', icon: 'bell', path: '/notifications', amountBadge: this.notification.amountOfUnreadNotifications$ },
      { label: 'Health', icon: 'heart', path: '/health' },
      { label: 'Bookings', icon: 'stethoscope', path: '/bookings' },
      { label: 'Secure Message', icon: 'forum-outline', path: '/secure-message' },
      { label: 'Payments', icon: 'credit-card-outline', path: '/payments' },
      { label: 'Account', icon: 'wrench', path: '/setup' },
    ];
    const loggedOutLinks = [
      { label: 'Log in', icon: 'login-variant', path: '/login' },
      { label: 'Sign up', icon: 'account-plus', path: '/register' },
      { label: 'Contact us', icon: 'phone', dialog: 'feedback' },
      { label: 'Back to Home Page', icon: 'arrow-left', path: '/landing' },
    ];

    this.toolbarLinks$ = this.loggedIn$.pipe(map((loggedIn) => (loggedIn ? loggedInLinks : loggedOutLinks)));
    // So this just checks when the screen changes to mobile
    // And it then closes or opens the sidebar as necessary
    this.isMobile$.pipe(takeUntil(this.unsubscribe$)).subscribe((isMobile) => {
      this.visible = !isMobile;
      this.mobile = isMobile;
    });

    // When we're in mobile and navigation starts, close the sidenav
    this.router.events
      .pipe(
        filter((evt) => evt instanceof ActivationStart),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        if (this.mobile) {
          this.visible = false;
        }
      });
  }

  toggle() {
    this.visible = !this.visible;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  linkActive(link: ToolbarLink) {
    return this.router.url.startsWith(link.path);
  }

  linkClicked(link: ToolbarLink) {
    this.isMobile$.subscribe((isMobile) => {
      this.visible = !isMobile;
    });
    if (link.path) {
      this.router.navigate([link.path]);
    } else {
      this.showDialog(link.dialog);
    }
  }

  showDialog(dialog: string) {
    if (dialog === 'about') {
      this.alert.openDialog$(AboutDialogComponent, { panelClass: 'gc-about-dialog' });
    } else if (dialog === 'feedback') {
      this.feedback.feedbackDialog(feedbackTypes.general).subscribe();
    }
  }
}
