<div class="container">
  <div class="cards">
    <mat-card>
      <mat-card-header class="settings-header">
        <mat-icon color="primary" svgIcon="lock-reset"></mat-icon>
        <span class="larger">Password Settings</span>
      </mat-card-header>
      <mat-card-content>
        <div class="card-content">
          <a mat-flat-button color="primary" [routerLink]="['/setup/settings/change-password']">
            <mat-icon svgIcon="lock-reset"></mat-icon>
            {{ (profile$ | async).hasPassword ? 'Change' : 'Set' }} your password
          </a>
          <a mat-flat-button color="primary" [routerLink]="['/setup/settings/reset-password']">
            <mat-icon svgIcon="lock-question"></mat-icon>
            Reset your password
          </a>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header class="settings-header">
        <mat-icon color="primary" svgIcon="share-variant"></mat-icon>
        <span class="larger">Sharing &amp; Privacy</span>
      </mat-card-header>
      <mat-card-content>
        <div class="card-button">
          <button mat-button mat-stroked-button color="primary" (click)="showPrivacyPolicy()">
            <mat-icon svgIcon="file-lock-outline"></mat-icon>
            View myGC's privacy policy
          </button>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="beta-tester-card">
      <div class="settings-header">
        <mat-icon color="primary" svgIcon="thumbs-up-down"></mat-icon>
        <span class="larger">Beta Tester</span>
      </div>
      <gc-beta-user [profile]="profile$ | async"></gc-beta-user>
    </mat-card>
    <div class="delete">
      <a mat-flat-button color="warn" (click)="onDeleteAccountClick()">
        <mat-icon svgIcon="delete-forever"></mat-icon>
        Delete my account
      </a>
    </div>
  </div>
</div>
