import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private readonly baseTitle = 'myGC';

  constructor(private readonly title: Title) {}

  setTitle(title?: string) {
    if (title) {
      this.title.setTitle(`${this.baseTitle} | ${title}`);
    } else {
      this.title.setTitle(this.baseTitle);
    }
  }

  getTitle(): string {
    return this.title.getTitle();
  }
}
