<div class="toolbar" *ngIf="visible" [@slideInOut]>
  <mat-icon (click)="visible = false" fxHide.gt-sm class="close">close</mat-icon>
  <a routerLink="/" *ngIf="isMobile$ | async; else desktopImg">
    <img [src]="mobileImgSrc" alt="myGC Logo" class="mobile-img" />
  </a>
  <ng-template #desktopImg>
    <a routerLink="/">
      <img [src]="desktopImgSrc" alt="myGC Logo" class="desktop-img" />
    </a>
  </ng-template>
  <div class="toolbar-link-container" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px">
    <div fxLayout="column" fxLayoutAlign="space-between" fxLayoutAlign.lt-md="start" fxFlex="grow">
      <div
        class="toolbar-link"
        [class.active]="linkActive(link)"
        *ngFor="let link of toolbarLinks$ | async"
        (click)="linkClicked(link)"
        fxLayout="column"
        fxLayout.lt-md="row"
        fxLayoutAlign="start center"
        fxLayoutGap.lt-md="32px"
        fxLayoutGap="0"
      >
        <mat-icon
          [matBadge]="(link.amountBadge | async) && (link.amountBadge | async) !== 0 ? '&#8288;' : ''"
          matBadgeColor="accent"
          matBadgeSize="small"
          [svgIcon]="link.icon"
        ></mat-icon>
        <span [innerHTML]="link.label"></span>
      </div>
    </div>
    <div *ngIf="loggedIn$ | async" fxLayout="column">
      <mat-divider fxHide.gt-sm></mat-divider>
      <div
        class="toolbar-link"
        [class.active]="linkActive(link)"
        *ngFor="let link of bottomLinks"
        fxLayout="column"
        fxLayout.lt-md="row"
        fxLayoutAlign="start start"
        fxLayoutAlign.lt-md="start center"
        fxLayoutGap.lt-md="32px"
        fxLayoutGap="0"
        (click)="showDialog(link.dialog)"
      >
        <mat-icon fxHide.gt-sm [svgIcon]="link.icon"></mat-icon>
        <span [innerHTML]="link.label" ngClass.gt-sm="desktop-bottom-link"></span>
      </div>
    </div>
  </div>
</div>
