import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Payment, PaymentData } from '@models/account.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(public http: HttpClient) {}

  getPayments$(): Observable<Payment[]> {
    return this.http.get<PaymentData[]>('/api/account/payments').pipe(map((payment) => payment.map((s) => new Payment(s))));
  }
}
