import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FamilyMember } from '@models/family-member.model';
import { Family } from '@models/family.model';
import { User, UserData, UserEditData } from '@models/user.model';
import { AlertService } from '@services/alert.service';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { FamilyService } from './family.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  profile$: ReplaySubject<User>;
  readonly families$ = new BehaviorSubject<Family[]>([]);
  private currentUserId: number;
  refresh$: Subject<void>;
  familyMembers$: Observable<FamilyMember[]>;

  constructor(
    private readonly http: HttpClient,
    private readonly family: FamilyService,
    private readonly alert: AlertService,
    private readonly auth: AuthService,
  ) {
    this.profile$ = new ReplaySubject<User>(1);
    this.profile$.subscribe((p) => {
      this.families$.next([]);
      if (p) {
        this.currentUserId = p.id;
        this.loadFamilies();
      }
    });
    // Get the family members
    this.familyMembers$ = this.families$.pipe(
      switchMap((familiesWithMembers) =>
        familiesWithMembers.length
          ? of([].concat(...familiesWithMembers.map((f) => f.members)) as FamilyMember[])
          : this.profile$.pipe(map((user: User) => [new FamilyMember({ ...user.rawData, role: 'owner', family_id: null })])),
      ),
      map((members) => [...new Set(members.map((m) => m.id))].map((id) => members.find((m) => m.id === id))),
    );
    this.family.familyCreated$.subscribe((f) => this.families$.next([f, ...this.families$.getValue()]));

    this.refresh$ = new Subject<void>();
    combineLatest([this.refresh$, this.auth.authenticated$.pipe(distinctUntilChanged(), startWith(this.auth.isAuthenticated()))])
      .pipe(
        map(([_, authenticated]) => authenticated),
        tap((authenticated) => {
          if (authenticated) {
            this.getUserProfile().subscribe();
          } else {
            this.profile$.next(null);
          }
        }),
      )
      .subscribe();
    this.refresh$.next();
  }

  private fetchUser() {
    return this.http.get<UserData>('/api/users').pipe(
      map((profile) => new User(profile)),
      tap((user) => this.profile$.next(user)),
    );
  }

  private loadFamilies() {
    this.family.getFamilies$().subscribe((families) => this.families$.next(families));
  }

  removeFamily(family: Family) {
    this.families$.next(this.families$.getValue().filter((f) => f !== family));
  }

  getUserProfile() {
    return this.fetchUser().pipe(
      take(1),
      tap((profile) => this.profile$.next(profile)),
    );
  }

  getUser(id: number) {
    return id && this.http.get<UserData>(`/api/users/${id}`).pipe(map((user) => new User(user)));
  }

  extractDobAndGender(idNumber: string): { dob: string; gender: string } {
    const date = idNumber.substring(0, 6);
    const dateOfBirth = this.calculateDob(date);
    const gender = Number(idNumber.charAt(6)) < 5 ? 'Female' : 'Male';
    return {
      dob: dateOfBirth,
      gender,
    };
  }

  calculateDob(date: string) {
    const year = DateTime.now().year.toString();
    const dobYear = date.substring(0, 2);
    const currentCentury = year.substring(0, 2);
    const currentYear = year.substring(2, 4);
    let dobCentury: number;
    if (dobYear > currentYear) {
      dobCentury = parseInt(currentCentury, 10) - 1;
      date = dobCentury.toString() + date;
    } else {
      date = currentCentury + date;
    }
    return DateTime.fromFormat(date, 'yyyyLLdd').toISODate();
  }

  extractInitials(name: string) {
    const initialsCheck = name.trim().match(/\b\w/g) || [];
    return initialsCheck.join('').toUpperCase();
  }

  updateUserProfile(data: UserEditData, userId?: number) {
    return this.http.patch<UserData>(`/api/users/${userId || this.currentUserId}`, data).pipe(
      map((profile) => profile && new User(profile)),
      tap((profile) => {
        if (profile) {
          if (userId === this.currentUserId || !userId) {
            this.profile$.next(profile);
          }
          if (profile.medicalAid?.dependantType === 'Main Member') {
            this.family.getFamilies$().pipe(take(1)).subscribe();
          }
        }
      }),
    );
  }

  changePassword(currentPassword: string, newPassword: string) {
    return this.http
      .post('/api/auth/change-password', {
        currentPassword,
        newPassword,
      })
      .pipe(
        catchError((err: unknown) => {
          this.alert.handleErrorDialog$(err, 'Unable to change password');
          return of(null);
        }),
        tap((res) => {
          if (res) {
            this.alert.snackbar(res.message);
          }
        }),
        map((res) => !!res),
        finalize(() => {
          this.getUserProfile().subscribe();
        }),
      );
  }
}
