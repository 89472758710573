import { ElementRef, Injectable } from '@angular/core';
import { ChatMessage, WebRTCService } from '@services/webrtc.service';
import * as moment from 'moment';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserService } from './user.service';
import { ConfigLocaleService } from './configLocale.service';

@Injectable({
  providedIn: 'root',
})
export class SimpleWebRTCService implements WebRTCService {
  private rtcUrl: string;
  private peer: SimpleWebRTC;
  userName: string;
  insideRoom$ = new BehaviorSubject<boolean>(false);
  messages$: ReplaySubject<ChatMessage> = new ReplaySubject(1);
  private readyToCall = false;
  constructor(private readonly user: UserService, private readonly configLocaleService: ConfigLocaleService) {
    this.user.profile$.pipe(filter((u) => !!u)).subscribe((u) => {
      this.userName = u.fullName;
    });
    this.configLocaleService.getValueByKey$(['rtc_url']).subscribe((config) => {
      this.rtcUrl = config.rtc_url;
    });
  }

  get ready() {
    return this.readyToCall;
  }

  connect(remoteVideos: ElementRef<HTMLElement>, localVideo: ElementRef<HTMLVideoElement>, debug = true) {
    if (!this.messages$ || this.messages$.isStopped) {
      this.messages$ = new ReplaySubject(1);
    }
    this.peer = new SimpleWebRTC({
      localVideoEl: localVideo.nativeElement,
      remoteVideosEl: remoteVideos.nativeElement,
      url: this.rtcUrl,
      autoRequestMedia: true,
      debug,
    });

    this.peer.on('connectionReady', () => {
      this.readyToCall = true;
    });
    this.peer.on('channelMessage', (_, channelLabel, { type, payload }) => {
      if (channelLabel === 'messaging' && type === 'direct-message') {
        this.messages$.next(payload);
      }
    });
    this.peer.on('peerStreamAdded', () => this.insideRoom$.next(true));
    this.peer.on('peerStreamRemoved', () => this.insideRoom$.next(false));
  }

  joinRoom(roomUuid: string) {
    this.peer.joinRoom(roomUuid);
    this.peer?.startLocalVideo();
  }

  leaveRoom() {
    this.peer?.leaveRoom();
    this.peer?.stopLocalVideo();
  }

  disconnect() {
    this.peer?.leaveRoom();
    this.peer?.disconnect();
    this.peer = null;
    this.messages$?.complete();
    this.messages$ = null;
    this.readyToCall = false;
  }

  sendMessage(message: string) {
    const msg: ChatMessage = {
      sender: this.userName,
      message,
      timestamp: moment().toISOString(),
    };
    this.peer.sendDirectlyToAll('messaging', 'direct-message', msg);
    this.messages$.next(msg);
  }

  changeCam(track: MediaStreamTrack) {
    if (this.peer.getPeers().length > 0) {
      this.peer
        .getPeers()[0]
        .pc.pc.getSenders()
        .forEach((sender) => {
          if (sender.track.kind === 'video') {
            sender.replaceTrack(track);
          }
        });
    }
  }
}
