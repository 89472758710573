import * as moment from 'moment';
import { Payment, PaymentData } from './account.model';
import { ConsentForm, ConsentFormData } from './consent-form.model';
import { DemographicRequest, DemographicRequestData } from './demographic-request.model';
import { Questionnaire, QuestionnaireData } from './questionnaire.model';
import { MedicalHistory } from './medical-history.model';

export class PackageData {
  data: any;
}

type RawItemData = ConsentFormData | PaymentData | QuestionnaireData | DemographicRequestData;
type RawItem = ConsentForm | Payment | Questionnaire | DemographicRequest;
export type ItemType = 'consent_form' | 'payment_request' | 'questionnaire' | 'demographic_request' | 'medical_history';
export type ItemData = { type: ItemType } & RawItemData;
export type Item = { type: ItemType; complete: boolean; created?: moment.Moment } & RawItem;

export class Package {
  type: string;
  label: string;
  icon: string;
  item: Item;

  constructor(data: ItemData) {
    this.type = data.type;
    const typeMap = {
      payment_request: { constructor: Payment, label: 'Payment', icon: 'credit-card-outline' },
      consent_form: { constructor: ConsentForm, label: 'Consent Form', icon: 'thumbs-up-down' },
      questionnaire: { constructor: Questionnaire, label: 'Questionnaire', icon: 'clipboard-text' },
      demographic_request: { constructor: DemographicRequest, label: 'Request', icon: 'lock' },
      medical_history: { constructor: MedicalHistory, label: 'Medical', icon: 'heart-pulse' },
    };
    this.type = data.type;
    const packageObj = typeMap[this.type];
    this.label = packageObj.label;
    this.icon = packageObj.icon;
    this.item = new packageObj.constructor(data);
  }

  isPayment(): this is PaymentPackage {
    const i = this.item;
    if (i.type === 'payment_request' || this.type === 'payment_request') {
      return true;
    }
    return false;
  }
}

export class PaymentPackage extends Package {
  item: Payment & { type: ItemType; complete: boolean };
}
