import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface RetryDialogData {
  title: string;
  message: string;
}

@Component({
  selector: 'gc-retry-dialog',
  templateUrl: 'retry-dialog.component.html',
  styleUrls: ['retry-dialog.component.scss'],
})
export class RetryDialogComponent implements OnDestroy {
  constructor(@Inject(MAT_DIALOG_DATA) public data: RetryDialogData, public dialogRef: MatDialogRef<RetryDialogComponent>) {}

  ngOnDestroy(): void {
    this.dialogRef = null;
  }

  retry() {
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
