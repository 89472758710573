import { Component, Input } from '@angular/core';

@Component({
  selector: 'gc-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
})
export class ProfilePictureComponent {
  @Input() imgSrc?: string;
  @Input() fullName?: string;

  get initials(): string {
    let name = this.fullName.trim();
    name = name.replace(/^(Dr|Prof)\.?\s*/i, '');
    const initialsCheck = name.match(/\b\w/g) || [];
    return ((initialsCheck.shift() || '') + (initialsCheck.pop() || '')).toUpperCase();
  }
}
