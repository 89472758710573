import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[gcAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  constructor(private readonly elementRef: ElementRef<HTMLInputElement>) {}

  ngAfterViewInit() {
    this.elementRef.nativeElement.focus();
  }
}
