import { ApplicationRef, Injectable } from '@angular/core';
import { interval, Observable, of } from 'rxjs';
import { delay, filter, first, map, retryWhen, switchMap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';

export interface WebSocketMessage<T = any> {
  channel: string;
  message?: T;
}

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket$: WebSocketSubject<WebSocketMessage<any>>;
  private readonly retryTime = 10 * 1000; // 10 seconds
  private _wsUrl: string = environment.wsUrl;

  constructor(private readonly appRef: ApplicationRef) {}

  private get wsUrl(): string {
    const url = new URL(this._wsUrl, this.location.href);
    url.protocol = this.location.protocol === 'https:' ? 'wss:' : 'ws:';
    return url.href;
  }

  // Private helper to enable mocking in tests
  private get location(): Location {
    return location;
  }

  // Private function in order to mock in tests
  private getWebSocket$(wsUrl: string): WebSocketSubject<WebSocketMessage<any>> {
    return webSocket(wsUrl);
  }

  connect(connectWsUrl = environment.wsUrl) {
    this._wsUrl = connectWsUrl;
    return of(this.wsUrl).pipe(
      switchMap((wsUrl) => {
        if (this.socket$ && !this.socket$.closed) {
          return this.socket$;
        } else {
          this.socket$ = this.getWebSocket$(wsUrl);
          return this.socket$;
        }
      }),
      retryWhen((errors$) => errors$.pipe(delay(this.retryTime))),
    );
  }

  waitUntilReady() {
    return this.appRef.isStable.pipe(
      first((stable) => stable),
      switchMap(() => interval(500)),
      first(() => this.socket$ && !this.socket$.closed),
    );
  }

  send<T>(channel: string, message: T) {
    const msg: WebSocketMessage<T> = { channel, message };
    this.socket$.next(msg);
  }

  close() {
    this.socket$?.complete();
    this.socket$ = null;
  }

  listen$<T>(channel: string): Observable<T> {
    return this.socket$.pipe(
      filter((data) => data.channel === channel),
      map((msg) => msg.message),
    );
  }
}
