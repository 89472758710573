function stringHexToRGB(hex: string): [string, string, string] {
  let colour = hex.replace(/^#/, ''); //remove hash
  if (colour.length === 3) {
    //sometimes hex values can be #AAA or #000
    colour = colour[0] + colour[0] + colour[1] + colour[1] + colour[2] + colour[2];
  }

  const [rStr, gStr, bStr] = [colour.slice(0, 2), colour.slice(2, 4), colour.slice(4, 6)];
  return [rStr, gStr, bStr];
}

// this function takes in a colour value as hex such as: #A53299,
// converts it to RGB values, and then darkens the RGB by adding a fixed amount to the rgb representations
// This is used to darken the values of the primary colour when having a custom theme, so that gradient
// effects still look good
export function darkenColour(colour: string, amount: number) {
  let [rStr, gStr, bStr] = stringHexToRGB(colour);
  const [r, g, b] = [parseInt(rStr, 16) + amount, parseInt(gStr, 16) + amount, parseInt(bStr, 16) + amount];

  rStr = Math.max(Math.min(255, r), 0).toString(16);
  gStr = Math.max(Math.min(255, g), 0).toString(16);
  bStr = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (rStr.length < 2 ? '0' : '') + rStr;
  const gg = (gStr.length < 2 ? '0' : '') + gStr;
  const bb = (bStr.length < 2 ? '0' : '') + bStr;

  return `#${rr}${gg}${bb}`;
}

// uses the formula from https://www.w3.org/TR/WCAG20/#contrast-ratiodef
// but substitutes relevant sides of equation with black and white values
// to check which contrast returns higher
// (L + 0.05) / (0.0 + 0.05) > (1.0 + 0.05) / (L + 0.05) use #000000 else use #ffffff
// then replace Luminance, L, with colour value calculated from current colourspace:
// https://www.w3.org/TR/WCAG20/#relativeluminancedef
export function calculateFontColour(colour: string) {
  const [rStr, gStr, bStr] = stringHexToRGB(colour);
  const [red, green, blue] = [parseInt(rStr, 16), parseInt(gStr, 16), parseInt(bStr, 16)];
  if (red * 0.299 + green * 0.587 + blue * 0.114 > 160) {
    return '#000';
  }
  return '#fff';
}

export function getBackground(colour: string): string {
  let firstColour = 'var(--primary-color)';
  let darkColour = 'var(--primary-darker-color)';
  if (colour) {
    firstColour = colour;
    darkColour = darkenColour(firstColour, -40);
  }
  return `linear-gradient(to bottom, ${firstColour} 13%, ${darkColour} 174%);`;
}

export function getFontColour(colour: string): string {
  if (colour) {
    return calculateFontColour(colour);
  }
  return '#FFF';
}
