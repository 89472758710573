import { Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, take, tap } from 'rxjs/operators';

@Component({
  selector: 'gc-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss'],
})
export class ChangeEmailComponent implements OnInit {
  @Output() loginSuccessful = new EventEmitter<void>();
  loginForm: FormGroup;
  showPassword = false;
  private code: string;
  validCode$: Observable<boolean>;
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly alertService: AlertService,
    private readonly auth: AuthService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly zone: NgZone,
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
    });
    this.route.queryParamMap
      .pipe(
        take(1),
        filter((params) => !!params.get('code')),
      )
      .subscribe((params) => {
        this.code = params.get('code');
        this.validCode$ = this.auth.verifyEmailRequestValidity$(this.code);
      });
  }

  updateEmail(method: string) {
    if (method === 'google') {
      this.auth.authenticated$.pipe(distinctUntilChanged()).subscribe((authenticated) => {
        if (authenticated) {
          this.zone.run(() => {
            this.update();
          });
        }
      });
    } else {
      const loginMethod$ = this.auth.login$(this.loginForm.value.email, this.loginForm.value.password);
      loginMethod$.subscribe(
        () => {
          this.zone.run(() => {
            this.update();
          });
        },
        (err: unknown) => {
          this.alertService.handleErrorDialog$(err, 'Unable to update email');
        },
      );
    }
  }

  update() {
    if (this.code) {
      this.auth
        .verifyEmailChange$(this.code)
        .pipe(
          catchError((err: unknown) => {
            this.alertService.handleErrorDialog$(err, 'Unable to update email');
            return of(false);
          }),
          tap(async () => this.router.navigate(['/dashboard'])),
        )
        .subscribe();
    }
  }

  signInWithGoogle() {
    this.auth.authenticated$.pipe(distinctUntilChanged()).subscribe((authenticated) => {
      if (authenticated) {
        this.zone.run(() => {
          this.loginSuccessful.emit();
        });
      }
    });
  }
}
