import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { ConfigLocaleService, ConfigRegex } from '@services/configLocale.service';
import { distinctUntilChanged, filter, finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'gc-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  passwordForm: FormGroup;
  returnUrl: string;
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly auth: AuthService,
    private readonly alert: AlertService,
    private readonly router: Router,
    private readonly activeRoute: ActivatedRoute,
    private readonly location: Location,
    private readonly configService: ConfigLocaleService,
  ) {}

  get valid(): boolean {
    const email = this.passwordForm.controls.email.value;
    const cellnr = this.passwordForm.controls.cellnr.value;
    return !(email || cellnr);
  }

  ngOnInit() {
    this.activeRoute.queryParamMap.subscribe((map: Params) => {
      this.returnUrl = map.get('returnUrl');
      if (this.returnUrl) {
        this.returnUrl = decodeURI(this.returnUrl);
      }
    });

    this.passwordForm = this.formBuilder.group({
      email: ['', Validators.email],
      cellnr: [''],
    });

    this.configService.getValueByKey$(['cell_validators_json']).subscribe((config) => {
      const cellValidators = JSON.parse(config.cell_validators_json) as ConfigRegex;
      this.passwordForm.get('cellnr').setValidators([Validators.pattern(new RegExp(cellValidators.pattern))]);
    });

    this.passwordForm
      .get('email')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((email) => (email ? this.passwordForm.get('cellnr').disable() : this.passwordForm.get('cellnr').enable()));
    this.passwordForm
      .get('cellnr')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((cellnr) => (cellnr ? this.passwordForm.get('email').disable() : this.passwordForm.get('email').enable()));
  }

  forgotPassword() {
    const { email, cellnr } = this.passwordForm.value;
    if (this.passwordForm.valid && (email || cellnr)) {
      this.auth
        .requestPasswordReset(email, cellnr)
        .pipe(
          filter((res) => !!res),
          tap(() => {
            this.alert
              .messageDialog$(
                'Notice',
                `We've successfully sent you a link via ${email ? 'email' : 'sms'}. Please check for the message for further instructions`,
                'confirm',
              )
              .pipe(
                finalize(() => {
                  if (this.returnUrl) {
                    this.router.navigateByUrl(this.returnUrl);
                  } else {
                    this.location.back();
                  }
                }),
              )
              .subscribe();
          }),
        )
        .subscribe();
    } else {
      this.alert.messageDialog$('Error', 'Please provide a valid email or cell number to reset your password', 'error');
      this.passwordForm.markAllAsTouched();
    }
  }
}
