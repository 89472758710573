<form class="password-form" [formGroup]="passwordForm">
  <mat-card>
    <mat-card-header class="card-header">
      <mat-card-title class="card-title">Reset your password</mat-card-title>
      <mat-card-subtitle class="card-subtitle">Select which details we should use to reset your password:</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="reset-password-field">
        <div class="cell">
          <mat-icon svgIcon="cellphone"></mat-icon>
          <span>via SMS:</span>
        </div>
        <gc-input-field formControlName="cellnr" type="tel" name="mobile" autocomplete="mobile" label="Mobile number"></gc-input-field>
      </div>
      <div class="reset-password-field">
        <div class="email">
          <mat-icon svgIcon="email-outline"></mat-icon>
          <span>via Email:</span>
        </div>
        <gc-input-field formControlName="email" type="email" name="email" autocomplete="email" label="Email address"></gc-input-field>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="forgotPassword()">
        <mat-icon svgIcon="send"></mat-icon>
        Send
      </button>
    </mat-card-actions>
  </mat-card>
</form>
