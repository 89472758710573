import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { LoaderComponent } from '@components/loader/loader.component';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private overlayRef: OverlayRef;
  private timer: number;
  open = false;
  openCount = 0;
  constructor(private readonly overlay: Overlay) {}

  show(timeout = 0) {
    if (this.openCount < 1) {
      const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
      this.overlayRef = this.overlay.create({
        hasBackdrop: true,
        backdropClass: 'light-blur',
        positionStrategy,
      });
      const loaderPortal = new ComponentPortal(LoaderComponent);
      const attach = () => this.overlayRef.attach(loaderPortal);
      if (timeout) {
        this.timer = setTimeout(attach, timeout) as unknown as number;
      } else {
        attach();
      }
    }
    this.openCount++;
  }

  hide() {
    if (this.openCount > 0) {
      this.openCount--;
    }
    if (this.openCount === 0) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.overlayRef?.dispose();
    }
  }
}
