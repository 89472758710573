import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExternalBookingLogData, LogData, OTPLogData, RouterLogData } from '@models/logs/logs.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private readonly uuid: string;
  private userJourney: string[] = [];

  constructor(private readonly http: HttpClient, private readonly deviceService: DeviceDetectorService) {
    if (sessionStorage.getItem('logging_uuid')) {
      this.uuid = sessionStorage.getItem('logging_uuid');
    } else {
      this.uuid = uuidv4();
      sessionStorage.setItem('logging_uuid', this.uuid);
    }
  }

  private baseLogInfo(type: string, context: string): LogData {
    return {
      log_type: type,
      session: this.uuid,
      context,
      device_info: this.deviceService.getDeviceInfo(),
      window_height: window.innerHeight,
      window_width: window.innerWidth,
    };
  }

  // Functions for router logging
  addToJourney(url: string) {
    this.userJourney.push(url);
  }

  logUserJourney$(url: string, context: string): Observable<unknown> {
    const routerLogData: RouterLogData = {
      ...this.baseLogInfo('router', context),
      url,
      journey: this.userJourney,
    };
    return this.http.post('/api/logs', routerLogData, { headers: { 'http-loader-disable': 'disable' } }).pipe(
      take(1),
      finalize(() => {
        this.userJourney = [];
      }),
    );
  }

  // Functions for OTP logging
  logOTPEntry$(context: string, numberSentTo: string = ''): Observable<unknown> {
    const otpLogData: OTPLogData = {
      ...this.baseLogInfo('otp_verification', context),
      number_sent_to: numberSentTo,
    };
    return this.http.post('/api/logs', otpLogData, { headers: { 'http-loader-disable': 'disable' } }).pipe(take(1));
  }

  logExternalBooking(context: string, url: string, diaryUuid: string, page: string, action: string, button: string) {
    const externalBookingLog: ExternalBookingLogData = {
      ...this.baseLogInfo('external_log', context),
      url,
      page,
      diary_uuid: diaryUuid,
      button: [button],
      action: [action],
    };
    return this.http.post('/api/logs', externalBookingLog, { headers: { 'http-loader-disable': 'disable' } }).pipe(take(1));
  }
}
