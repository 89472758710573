import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
@Component({
  selector: 'gc-google-btn',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.scss'],
})
export class GoogleLoginComponent implements AfterViewInit {
  @ViewChild('googleBtn') googleBtn!: ElementRef;
  @Output() googleButtonFunction = new EventEmitter<void>();
  @Input() buttonType: string | undefined;
  constructor(
    private readonly auth: AuthService,
    private readonly alert: AlertService,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    if (this.googleBtn) {
      this.loadScript();
    }
  }

  // Call this function in the constructor of a page where google sign is needed
  loadScript(): void {
    const script = this.renderer.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.onload = () => {
      this.initializeGoogleAccount();
      this.renderGoogleButton();
    };
    this.renderer.appendChild(this.elementRef.nativeElement, script);
  }

  initializeGoogleAccount(): void {
    google.accounts.id.initialize({
      client_id: this.auth.googleClientID,
      callback: (resp: google.accounts.id.CredentialResponse) => {
        if (resp) {
          this.auth.postGoogleSignInCredentials(resp.credential);
        } else {
          this.alert.notifyError$('Error in Google Sign in');
        }
      },
    });
  }

  renderGoogleButton(): void {
    google.accounts.id.renderButton(this.googleBtn.nativeElement, {
      type: 'standard',
      theme: 'filled_blue',
      size: 'medium',
      shape: 'pill',
      logo_alignment: 'left',
      text: this.buttonType === 'signin_with' ? 'signin_with' : 'signup_with',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      click_listener: () => {
        this.googleButtonFunction.emit();
      },
    });
  }
}
