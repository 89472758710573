import { Payment, PaymentData } from '@models/account.model';
import { Booking, BookingData } from '@models/booking.model';
import { DemographicRequest, DemographicRequestData } from '@models/demographic-request.model';
import { ClinicalDocument, ClinicalDocumentData } from '@models/clinical-document.model';
import { ConsentForm, ConsentFormData } from '@models/consent-form.model';
import { Questionnaire, QuestionnaireData } from '@models/questionnaire.model';
import { MedicalHistory, MedicalHistoryData } from '@models/medical-history.model';

export interface NotificationData {
  id: number;
  parent_notification_id: number;
  type: string;
  subtype?: string;
  identifier: string;
  sent_date: string;
  read_date?: string;
  doctor?: string;
  booking?: BookingData;
  payment?: PaymentData;
  ozowPaymentUrl?: string;
  stripePaymentUrl?: string;
  peachpayPaymentUrl?: string;
  fnbPaymentUrl?: string;
  document?: ClinicalDocumentData;
  demographicRequest?: DemographicRequestData;
  medicalHistoryRequest?: MedicalHistoryData;
  questionnaire?: QuestionnaireData;
  consentForm?: ConsentFormData;
  icon?: string;
}

export class Notification {
  static types = {
    telemed: {
      icon: 'video',
      path: '/telehealth',
    },
    questionnaire: {
      icon: 'clipboard-text',
      path: '/health/questionnaires',
      params: {
        id: 'identifier',
      },
    },
    'consent-form': {
      icon: 'thumbs-up-down',
      path: '/health/consent-forms',
      params: {
        id: 'identifier',
      },
    },
    document: {
      icon: 'file-document',
      path: '/health/clinical-documents',
    },
    'demographic-request': {
      icon: 'account-arrow-right-outline',
      path: '/health/demographic-requests',
    },
    message: {
      icon: 'email',
    },
    booking: {
      icon: 'calendar-today',
      path: '/bookings/my-bookings',
    },
    payment: {
      icon: 'credit-card-outline',
      path: '/payments',
      queryParams: {
        hash: 'identifier',
      },
    },
    'medical-history': {
      icon: 'heart-pulse',
      path: '/health/medical-history',
      params: {
        id: 'identifier',
      },
    },
  };

  id: number;
  parentNotificationId?: number;
  type: string;
  subtype?: string;
  identifier?: string;
  description: string;
  sentDate: string;
  readDate?: string;
  doctor?: string;
  payment?: Payment;
  booking?: Booking;
  document?: ClinicalDocument;
  demographicRequest?: DemographicRequest;
  medicalHistoryRequest?: MedicalHistory;
  questionnaire?: Questionnaire;
  consentForm?: ConsentForm;
  icon?: string;
  actionName?: string;
  actionRequired?: boolean;

  constructor(data: NotificationData) {
    this.id = data.id;
    this.parentNotificationId = data.parent_notification_id;
    this.identifier = data.identifier;
    this.type = data.type;
    this.subtype = data.subtype;
    this.sentDate = data.sent_date;
    this.doctor = data.doctor;
    this.readDate = data.read_date;
    this.booking = data.booking ? new Booking(data.booking) : null;
    this.payment = data.payment ? new Payment(data.payment) : null;
    this.document = data.document ? new ClinicalDocument(data.document) : null;
    this.demographicRequest = data.demographicRequest ? new DemographicRequest(data.demographicRequest) : null;
    this.medicalHistoryRequest = data.medicalHistoryRequest ? new MedicalHistory(data.medicalHistoryRequest) : null;
    this.questionnaire = data.questionnaire ? new Questionnaire(data.questionnaire) : null;
    this.consentForm = data.consentForm ? new ConsentForm(data.consentForm) : null;
    this.icon = data.icon;
  }
}
