<mat-toolbar color="primary" class="toolbar">
  <mat-toolbar-row>
    <button mat-icon-button type="button" (click)="hamburgerClick.emit($event)" fxHide.gt-sm>
      <mat-icon svgIcon="menu"></mat-icon>
    </button>
    <div fxHide.lt-md class="heading" fxLayout="row" fxLayoutAlign="center center" *ngIf="router.url === '/dashboard'; else subPage">
      <mat-icon class="big-icon" [svgIcon]="greetingIcon$ | async"></mat-icon>
      <div class="text" fxLayout="column">
        <span class="greeting">
          {{ greetingTitle$ | async }}
        </span>
        <span class="name" *ngIf="userName$ | async as userName">{{ userName }}!</span>
      </div>
    </div>
    <ng-template #subPage>
      <div class="heading" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon ngClass.gt-sm="big-icon" class="page-icon" [svgIcon]="icon"></mat-icon>
        <div class="text" fxLayout="column">
          <span class="title" fxHide.lt-md>
            {{ title }}
          </span>
        </div>
      </div>
    </ng-template>
    <span fxFlex="1 1 auto"></span>
    <div class="header-buttons" fxLayout="row" fxLayoutGap="8px">
      <button mat-icon-button *ngIf="updateAvailable$ | async" (click)="updateApplication()" matTooltip="Update Available">
        <mat-icon matBadge="!" matBadgeColor="accent" svgIcon="cellphone-arrow-down"></mat-icon>
      </button>
      <gc-search-input fxHide.lt-md [formControl]="searchTerm" placeholder="Can we help you find something?"></gc-search-input>
      <button fxHide.gt-sm mat-icon-button type="button" (click)="onSearchClick()">
        <mat-icon svgIcon="magnify"></mat-icon>
      </button>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <button mat-icon-button type="button" routerLink="/notifications">
          <mat-icon
            [matBadge]="(amountOfUnreadNotifications$ | async) !== 0 ? '&#8288;' : ''"
            matBadgeColor="accent"
            matBadgeSize="small"
            svgIcon="bell"
          ></mat-icon>
        </button>
        <button
          mat-icon-button
          class="profile-picture"
          *ngIf="profilePicture$ | async; else noProfilePicture"
          type="button"
          routerLink="/setup/my-account"
        >
          <gc-profile-picture [imgSrc]="profilePicture$ | async" mat-card-avatar></gc-profile-picture>
        </button>
        <ng-template #noProfilePicture>
          <button mat-icon-button type="button" routerLink="/setup/my-account">
            <mat-icon svgIcon="account-circle"></mat-icon>
          </button>
        </ng-template>
        <button
          mat-icon-button
          [matMenuTriggerFor]="extraMenu"
          type="button"
          (menuOpened)="dropdownMenuVisible.emit(true)"
          (menuClosed)="dropdownMenuVisible.emit(false)"
          id="topNavBarToggleButton"
        >
          <mat-icon svgIcon="dots-vertical"></mat-icon>
        </button>
        <mat-menu #extraMenu="matMenu">
          <button mat-menu-item [routerLink]="['/setup/my-account']">
            <mat-icon svgIcon="account-circle"></mat-icon>
            <span>My Account</span>
          </button>
          <button mat-menu-item [routerLink]="['/setup/medical-aid']">
            <mat-icon svgIcon="hospital-box"></mat-icon>
            <span>Medical Aid</span>
          </button>
          <button mat-menu-item [routerLink]="['/setup/family-management']">
            <mat-icon svgIcon="account-group"></mat-icon>
            <span>Family Management</span>
          </button>
          <button mat-menu-item [routerLink]="['/setup/settings']">
            <mat-icon svgIcon="cog"></mat-icon>
            <span>Settings</span>
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon svgIcon="logout"></mat-icon>
            <span>Log out</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row class="mobile-dashboard-row" fxHide.gt-sm *ngIf="router.url === '/dashboard'">
    <div fxHide.gt-sm class="heading" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="1 1 auto">
      <div class="text" fxLayout="column">
        <span class="greeting">
          {{ greetingTitle$ | async }}
        </span>
        <span class="name" *ngIf="userName$ | async as userName">{{ userName }}!</span>
      </div>
      <mat-icon class="big-icon" [svgIcon]="greetingIcon$ | async"></mat-icon>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
