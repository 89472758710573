import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '@services/settings.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'gc-recover-account',
  templateUrl: './recover-account.component.html',
  styleUrls: ['./recover-account.component.scss'],
})
export class RecoverAccountComponent implements OnInit {
  constructor(private readonly settings: SettingsService, private readonly route: ActivatedRoute, private readonly router: Router) {}

  ngOnInit() {
    this.route.queryParamMap
      .pipe(
        tap((params) => {
          const code = params.get('code');
          if (code) {
            this.settings.recoverAccount(code).subscribe(() => {
              this.router.navigate(['/']);
            });
          } else {
            // TODO: redirect to error page once that is in.
            this.router.navigate(['/']);
          }
        }),
      )
      .subscribe();
  }
}
