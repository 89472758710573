import { AfterViewInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TermsAndConditionsDialogComponent } from '@dialogs/terms-and-conditions-dialog/terms-and-conditions-dialog.component';

@Component({
  selector: 'gc-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements AfterViewInit {
  @Output() acceptTerms = new EventEmitter<boolean>();
  @Input() register = false;
  @Input() startValue = false;

  constructor(private readonly dialog: MatDialog) {}

  ngAfterViewInit(): void {
    this.acceptTerms.emit(this.startValue);
  }

  onChange($event) {
    this.acceptTerms.emit($event.checked);
  }

  showTermsAndConditions() {
    this.dialog.open(TermsAndConditionsDialogComponent, {
      panelClass: 'terms-conditions-dialog',
      height: '95%',
      width: '95%',
    });
  }
}
