import * as moment from 'moment';

// These values are optained from goodxweb and should handled as constants
export type durationOptions = '0' | '1-5' | '5-10' | '10-15' | '15-20' | '20+';
export type frequencyOptions = '0' | '1' | '2' | '3' | '4' | '5';
export type recreationalDrugsOptions = 'Never' | 'Previously' | 'Regularly' | 'Occasional' | 'Rarely';
export interface GeneralHealthData {
  alcohol: { note: string; value: 'Ex-Smoker' | durationOptions };
  smoking: { note: string; value: durationOptions };
  exercise: { note: string; value: frequencyOptions };
  recreational_drugs: { note: string; value: recreationalDrugsOptions };
}

export interface FamilyHistoryData {
  note: string;
  value: string;
  description: string;
}

interface AllergyPackage {
  note: string;
  code: string;
  type: string;
  description: string;
}
export interface AllergiesData {
  no_allergies: string;
  warning: { urgent: boolean; message: string };
  drug_classes: AllergyPackage[];
  natural_ingredients: AllergyPackage[];
  chemical_ingredients: AllergyPackage[];
}

export interface OperationHistoryData {
  date: string;
  note: string;
  doctor: string;
  operation: string;
  hospital: string;
}
export interface TreatmentHistoryData {
  place: string;
  dr_note: string;
  begin_date: string;
  end_date: string;
  description: string;
}

export interface OccupationalAndDemographicalHistoryData {
  note: string;
  occupation: string;
}

export interface VaccinationsData {
  date: string;
  vaccination: string;
}
export interface TravelHistoryData {
  to: string;
  from: string;
  location: string;
  additional_information: string;
}
export interface GynaecologicalHistoryData {
  number_of_pregnancies: number;
  pregnancies: { date: string; pregnancy_outcome: string; baby: string; doctor: string; notes_complications_other: string };
}

export interface ChronicDiagnosticData {
  date: string;
  note: string;
  diagnosis?: [
    {
      description: string; //diagnosis text name
    },
  ];
  // medication package
  default_lines?: [
    {
      diagnosis?: [
        {
          description: string; // diagnosis linked to medication text name
        },
      ];
      description?: string; // medication text name
    },
  ];
}

export interface MedicalHistoryData {
  uuid?: string;
  booking_uuid?: string;
  required_fields?: string[];
  accepted?: boolean;
  rejected?: boolean;
  doctor_name?: string;
  doctor_logo?: string;
  requested_timestamp?: string;
  responded_timestamp?: string;
  user_id?: number;
  general_health?: GeneralHealthData;
  family_history?: FamilyHistoryData[];
  chronic_items?: ChronicDiagnosticData[]; // medication and diagnosis are in the same array
  allergies?: AllergiesData;
  operation_history?: OperationHistoryData[];
  treatment_history?: TreatmentHistoryData[];
  occupational_and_demographical_history?: OccupationalAndDemographicalHistoryData;
  vaccinations?: VaccinationsData[];
  travel_history?: TravelHistoryData[];
  gynaecological_history?: GynaecologicalHistoryData;
}

export class MedicalHistory {
  uuid?: string;
  bookingUuid?: string;
  userId: number;
  doctorName?: string;
  doctorLogo?: string;
  accepted?: boolean;
  rejected?: boolean;
  requestedOn?: moment.Moment;
  respondedOn?: moment.Moment;
  requiredFields?: string[];
  generalHealth?: GeneralHealthData;
  familyHistory?: FamilyHistoryData[];
  chronicItems?: ChronicDiagnosticData[];
  allergies?: AllergiesData;
  operationHistory?: OperationHistoryData[];
  treatmentHistory?: TreatmentHistoryData[];
  occupationalAndDemographicalHistory?: OccupationalAndDemographicalHistoryData;
  vaccinations?: VaccinationsData[];
  travelHistory?: TravelHistoryData[];
  gynaecologicalHistory?: GynaecologicalHistoryData;

  constructor(data: MedicalHistoryData) {
    this.uuid = data.uuid;
    this.bookingUuid = data.booking_uuid;
    this.userId = data.user_id;
    this.accepted = data.accepted;
    this.rejected = data.rejected;
    this.respondedOn = data.responded_timestamp && moment(data.responded_timestamp);
    this.doctorLogo = data.doctor_logo;
    this.doctorName = data.doctor_name;
    this.generalHealth = data.general_health;
    this.familyHistory = data.family_history;
    this.chronicItems = data.chronic_items;
    this.allergies = data.allergies;
    this.operationHistory = data.operation_history;
    this.treatmentHistory = data.treatment_history;
    this.occupationalAndDemographicalHistory = data.occupational_and_demographical_history;
    this.vaccinations = data.vaccinations;
    this.travelHistory = data.travel_history;
    this.gynaecologicalHistory = data.gynaecological_history;
  }

  get complete() {
    return this.accepted || this.rejected;
  }
}
