import * as moment from 'moment';
import { Duration } from 'moment';

export interface TimeslotDay {
  date: string;
  timeslots: string[];
}

export interface BookingTypeData {
  uuid: string;
  name: string;
  days?: { [date: string]: string[] };
  telemed: boolean;
  duration: Duration;
}

export class BookingType {
  readonly uuid: string;
  name: string;
  days?: TimeslotDay[];
  telemed: boolean;
  duration: Duration;

  constructor(bookingTypeData?: BookingTypeData) {
    if (bookingTypeData) {
      this.uuid = bookingTypeData.uuid;
      this.name = bookingTypeData.name;
      this.days = this.sortTimeslots(bookingTypeData.days);
      this.telemed = bookingTypeData.telemed ?? false;
      this.duration = moment.duration(bookingTypeData.duration, 'minutes');
    }
  }

  get earliestTimeslotDay(): TimeslotDay {
    return this.days.find((days) => days.date && days.timeslots?.length);
  }

  get hasDays(): boolean {
    return !!this.days.length;
  }

  get icon() {
    let icon: string;
    if (this.telemed) {
      icon = 'video';
    } else if (this.name?.toLowerCase().includes('script')) {
      icon = 'pill';
    } else if (this.name?.toLowerCase().replace(/\W+/, '').includes('followup')) {
      icon = 'rotate-right';
    } else {
      icon = 'account-group';
    }
    return icon;
  }

  sortTimeslots(days: { [date: string]: string[] }): TimeslotDay[] {
    return Object.entries(days || {}).map(([date, timeslots]) => ({ date: moment(date).format('YYYY-MM-DD'), timeslots }));
  }
}
