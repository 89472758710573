import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RegistrationDetails } from '@models/registration-details.model';
import { User } from '@models/user.model';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { LoggingService } from './logging.service';

export interface GoogleDetails {
  state: string;
  redirect_uri: string;
  client_id: string;
  response_type: string;
  nonce: string;
  scope: string;
}

export interface GoogleSignIn {
  client_id?: string;
  jwt?: string;
  access_token?: string;
  refresh_token?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  @Output() loginSuccessful = new EventEmitter<void>();
  readonly DISCOVERY_URI = 'https://accounts.google.com/.well-known/openid-configuration';
  private readonly REDIRECT_URL_KEY = 'redirectUrl';

  private _redirectUrl: string; // eslint-disable-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match

  accessToken$ = new Subject<string | null>();
  googleId = '727367228075-a6u5kim7hkai9s280p5fj6ieak3lind2.apps.googleusercontent.com';
  authenticated$: BehaviorSubject<boolean>;

  constructor(
    private readonly logger: LoggingService,
    private readonly http: HttpClient,
    private readonly jwt: JwtHelperService,
    private readonly alert: AlertService, // private readonly zone: NgZone,
  ) {
    this.authenticated$ = new BehaviorSubject<boolean>(this.isAuthenticated());
  }

  set redirectUrl(url: string) {
    this._redirectUrl = url;
    localStorage.setItem(this.REDIRECT_URL_KEY, url);
  }

  get redirectUrl() {
    if (!this._redirectUrl) {
      this._redirectUrl = localStorage.getItem(this.REDIRECT_URL_KEY);
    }
    return this._redirectUrl;
  }

  get googleClientID(): string {
    return this.googleId;
  }

  removeCookie(cookieName: string) {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  handleError<T>(fallback?: T) {
    return () => of(fallback as T);
  }

  register$(registrationDetails: RegistrationDetails): Observable<User> {
    return this.http.post<{ user: User; access_token: string; refresh_token: string }>('/api/auth/register', registrationDetails).pipe(
      tap((res) => {
        this.setToken(res.access_token);
        this.setRefreshToken(res.refresh_token);
        if (res.access_token) {
          this.authenticated$.next(true);
        }
      }),
      map((res) => res.user),
    );
  }

  login$(identifier: string, password: string, remember = false): Observable<boolean> {
    const body = { identifier, password };
    return this.http.post<{ access_token: string; refresh_token: string }>('/api/auth/login', body).pipe(
      tap((res) => {
        this.setToken(res.access_token, remember ? localStorage : sessionStorage);
        if (remember) {
          this.setRefreshToken(res.refresh_token);
        }
      }),
      map((res) => !!res.access_token),
      tap((success) => this.authenticated$.next(success)),
    );
  }

  logout() {
    // TODO: Replace with actual backend logout
    return new Observable<void>((subscribers) => {
      if (google.accounts && google.accounts.id) {
        google.accounts.id.disableAutoSelect();
      }
      localStorage.removeItem(environment.accessTokenKey);
      localStorage.removeItem(environment.refreshTokenKey);
      sessionStorage.removeItem(environment.accessTokenKey);
      sessionStorage.removeItem(environment.refreshTokenKey);
      this.authenticated$.next(false);
      subscribers.next();
      subscribers.complete();
    });
  }

  private setToken(token: string, storage: Storage = localStorage) {
    if (token) {
      storage.setItem(environment.accessTokenKey, token);
    }
  }

  private setRefreshToken(token: string, storage: Storage = localStorage) {
    if (token) {
      storage.setItem(environment.refreshTokenKey, token);
    }
  }

  private getRefreshToken() {
    return localStorage.getItem(environment.refreshTokenKey);
  }

  isAuthenticated() {
    const refreshToken = this.getRefreshToken();
    // this.jwt.isTokenExpired() uses the tokenGetter function to get the token
    // as exlained in app.module.ts
    return !this.jwt.isTokenExpired() || (!!refreshToken && !this.jwt.isTokenExpired(refreshToken));
  }

  getUserId(): null | number {
    const token = localStorage.getItem(environment.accessTokenKey);
    if (!token) {
      return null;
    }
    const decoded = this.jwt.decodeToken(token);
    if (!decoded || !decoded.sub) {
      return null;
    }
    const userId = Number(decoded.sub);
    return userId;
  }

  refresh() {
    const token = this.getRefreshToken();
    if (!token) {
      return throwError(null);
    }
    const headers = { Authorization: `Bearer ${token}` }; // eslint-disable-line @typescript-eslint/naming-convention
    return this.http.get<{ access_token: string }>('/api/auth/refresh', { headers }).pipe(
      tap((res) => {
        this.setToken(res.access_token);
      }),
      map((res) => res.access_token),
      tap((res) => {
        this.authenticated$.next(!!res);
      }),
    );
  }

  requestPasswordReset(email?: string, cellnr?: string) {
    const body = { email, cellnr };
    return this.http.post<{ success: boolean; message: string }>('/api/auth/password-reset/request', body).pipe(
      catchError((err: unknown) => {
        this.alert.handleErrorDialog$(err, 'Unable to request a password reset');
        return of({ success: false });
      }),
      map((res) => !!res?.success),
    );
  }

  verifyPasswordReset(code: string, password: string) {
    return this.http
      .post<{ success: boolean; message: string }>('/api/auth/password-reset/verify', {
        code,
        password,
      })
      .pipe(
        catchError((err: unknown) => {
          this.alert.handleErrorDialog$(err, 'Unable to reset password');
          return of({ success: false, message: (err as HttpErrorResponse).message });
        }),
      );
  }

  requestMobileVerification$(newNumber: string = null): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>('/api/auth/verify/mobile/request', { mobile_number: newNumber }).pipe(
      tap((result) => {
        this.logger
          .logOTPEntry$(
            `OTP requested for ${newNumber ? 'new number: ' + newNumber : 'existing number'} with result: ${result.message} `,
            newNumber,
          )
          .subscribe();
      }),
      catchError((err: unknown) => {
        this.alert.handleErrorDialog$(err, 'Unable to request mobile verification');
        return of({ success: false, message: (err as HttpErrorResponse).message });
      }),
    );
  }

  changeEmail(email: string, newEmail: string) {
    return this.http.post('/api/auth/email', { old_email: email, new_email: newEmail });
  }

  verifyEmailRequestValidity$(code: string): Observable<boolean> {
    return this.http.get(`/api/auth/email/${code}`).pipe(catchError(this.handleError(null)));
  }

  verifyEmailChange$(code: string): Observable<boolean> {
    return this.http.patch(`/api/auth/email/${code}`, { responseType: 'text' }).pipe(catchError(this.handleError(null)));
  }

  blockEmailChange(code: string) {
    return this.http.delete(`/api/auth/email/${code}`).pipe(catchError(this.handleError(null)));
  }

  verifyEmail(code: string) {
    return this.http.post('/api/auth/verify/email', { code }, { responseType: 'text' }).pipe(catchError(this.handleError(null)));
  }

  verifyMobile(code: string) {
    return this.http.post<{ status: string; message: string }>('/api/auth/verify/mobile', { code });
  }

  postGoogleSignInCredentials = (credential: string): void => {
    this.http
      .post<GoogleSignIn>('/api/auth/google/token', { jwt: credential }, { observe: 'response' })
      .pipe(
        map((resp: HttpResponse<GoogleSignIn>) => {
          if (!resp.body) {
            throw new Error('Response have no body');
          }
          this.accessToken$.next(credential);
          if (resp.body.access_token) {
            this.setToken(resp.body.access_token, localStorage);
          }
          if (resp.body.refresh_token) {
            this.setRefreshToken(resp.body.refresh_token, localStorage);
          }
          return resp.body;
        }),
        map((res) => !!res.access_token),
        tap((success) => {
          this.authenticated$.next(success);
        }),
        catchError((error: unknown) => {
          this.alert.handleErrorDialog$(error, 'Unable to reset password');
          return of({ success: false, message: (error as HttpErrorResponse).message });
        }),
      )
      .subscribe();
  };

  checkAuthentication() {
    return this.http.get<{ authenticated: boolean }>('/api/auth/authenticated').pipe(
      catchError(() => of({ authenticated: false })),
      map((result) => result.authenticated),
      tap((authenticated) => {
        this.authenticated$.next(authenticated);
      }),
    );
  }

  checkVerification() {
    return this.http.get<{ email: boolean; cellnr: boolean }>('/api/auth/verified');
  }
}
