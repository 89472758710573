import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'gc-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  constructor(private readonly auth: AuthService, private readonly route: ActivatedRoute, private readonly router: Router) {}

  ngOnInit() {
    this.route.queryParamMap
      .pipe(
        tap((params) => {
          const code = params.get('code');
          if (code) {
            this.auth.verifyEmail(code).subscribe(() => {
              this.router.navigate(['/']);
            });
          }
        }),
      )
      .subscribe();
  }
}
