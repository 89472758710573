<div class="terms-box" fxLayout="row" fxLayoutAlign="center">
  <mat-checkbox class="terms-btn" (change)="onChange($event)" [checked]="startValue">
    <span *ngIf="register">
      I agree to be registered on
      <b style="margin-right: 2px"><i>myGC</i></b>
      &nbsp;&amp;&nbsp;
    </span>
    I accept the
    <button type="button" (click)="showTermsAndConditions()">Terms and Conditions</button>
  </mat-checkbox>
</div>
