export interface Question<T, U = T> {
  value?: T;
  key: string;
  label?: string;
  required?: boolean;
  type?: string;
  regex?: RegExp;
  // Only useful for type='dropdown'
  optionValue?: (value: T) => U;
  optionLabel?: (value: T) => string;
  optionHint?: (value: U) => string;
  optionClass?: (value: U) => string;
  options?: T[];
  show?: (value: { [key: string]: any }) => boolean;
}

export const DEFAULT_QUESTION: Question<any> = {
  key: 'result',
  label: '',
  type: 'text',
  show: () => true,
};
