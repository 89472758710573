<div class="container">
  <mat-card *ngIf="booking; else loading">
    <img mat-card-image src="/assets/images/success.svg" alt="success icon" />
    <mat-card-header>
      <mat-card-title>Booking Successfully Confirmed</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <gc-booking-display-card [booking]="booking"></gc-booking-display-card>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #loading>
  <mat-card *ngIf="!error; else noBooking">
    <h2>Loading...</h2>
    <mat-spinner class="mtop"></mat-spinner>
  </mat-card>
  <ng-template #noBooking>
    <mat-card>
      <mat-card-header>
        <mat-card-title>No booking</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>Sorry. We could not find your booking.</p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" routerLink="/bookings/my-bookings">Go to Bookings</button>
      </mat-card-actions>
    </mat-card>
  </ng-template>
</ng-template>
