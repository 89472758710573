<div class="container">
  <div class="content">
    <gc-logged-out-header></gc-logged-out-header>
    <div class="logo-backdrop">
      <button class="back-btn" mat-button type="button" routerLink="/landing">
        <mat-icon svgIcon="keyboard-backspace"></mat-icon>
        Back
      </button>
    </div>
    <div class="sign-up-container">
      <div class="sign-up">
        <gc-login-form (loginSuccessful)="login()"></gc-login-form>
      </div>
      <mat-card class="signup-card">
        <img class="img-container" src="assets/images/myGCLogo_default-white.svg" alt="myGC Logo" />
        <mat-card-header class="signup-prompt-text">
          <mat-card-title>Don't have an account yet?</mat-card-title>
          <mat-card-subtitle>
            Sign up to get access to all
            <br />
            myGC's great features!
          </mat-card-subtitle>
        </mat-card-header>
        <div class="signup-prompt-button">
          <button class="signup-btn" mat-raised-button type="button" routerLink="/register">
            <mat-icon svgIcon="account-plus"></mat-icon>
            <span>Sign up</span>
          </button>
        </div>
      </mat-card>
    </div>
    <div class="signup-prompt-button mobile">
      <button class="signup-btn" mat-raised-button type="button" routerLink="/register">
        <mat-icon svgIcon="account-plus"></mat-icon>
        <span>Sign up</span>
      </button>
      <button color="accent" mat-raised-button type="button" (click)="feedback()">
        <mat-icon svgIcon="comment-quote"></mat-icon>
        Feedback
      </button>
    </div>
  </div>
</div>
