import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Notification } from '@models/notifications/notification.model';
import { FilterType } from '@modules/shared/components/type-filter/type-filter.component';
import { DisplayService } from '@services/display.service';
import { NotificationService } from '@services/notification.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'gc-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  sort: MatSort;
  searchControl: FormControl = new FormControl();
  // Underlying notifications data.
  _notifications: Notification[] = [];
  // Datasource proxy for notifications. Used for sorting and filtering.
  _notificationsDataSource: MatTableDataSource<Notification> = new MatTableDataSource();
  // Notifications observable to be used by the UI.
  notifications$: Observable<Notification[]> = this._notificationsDataSource.connect();
  isMobile$: BehaviorSubject<boolean>;
  searchMode = false;
  typeFilters: FilterType[] = [
    {
      title: 'Telemed',
      type: 'telemed',
      icon: Notification.types.telemed.icon,
      selected: false,
    },
    {
      title: 'Questionnaires',
      type: 'questionnaire',
      icon: Notification.types.questionnaire.icon,
      selected: false,
    },
    {
      title: 'Consent Forms',
      type: 'consent-form',
      icon: Notification.types['consent-form'].icon,
      selected: false,
    },
    {
      title: 'Documents',
      type: 'sicknote',
      icon: Notification.types.document.icon,
      selected: false,
    },
    {
      title: 'Payments',
      type: 'payment',
      icon: Notification.types.payment.icon,
      selected: false,
    },
    {
      title: 'Bookings',
      type: 'booking',
      icon: Notification.types.booking.icon,
      selected: false,
    },
    {
      title: 'Demographic Request',
      type: 'demographic-request',
      icon: Notification.types['demographic-request'].icon,
      selected: false,
    },
    {
      title: 'Messages',
      type: 'message',
      icon: Notification.types.message.icon,
      selected: false,
    },
    {
      title: 'Medical History',
      type: 'medical-history',
      icon: Notification.types['medical-history'].icon,
      selected: false,
    },
  ];
  noNotifications = false;

  constructor(
    private readonly ref: ChangeDetectorRef,
    private readonly notification: NotificationService,
    public display: DisplayService,
  ) {}
  @ViewChild(MatSort) set content(content: MatSort) {
    if (content) {
      this.sort = content;
      this._notificationsDataSource.sort = this.sort;
      this.ref.detectChanges();
    }
  }

  ngOnInit() {
    this.isMobile$ = this.display.isMobile$;
    this._notificationsDataSource.paginator = this.paginator;
    this._notificationsDataSource.filterPredicate = (data, filter) =>
      ['type', 'sentDate', 'description'].some((f) => data[f].toLowerCase().indexOf(filter) !== -1);
    this.searchControl.valueChanges.subscribe((searchTerm) => {
      this._notificationsDataSource.filter = searchTerm.trim().toLowerCase();
      this._notificationsDataSource.paginator.firstPage();
    });
    this.updateNotifications();
  }

  onFilter(data: Notification[]) {
    this._notificationsDataSource.data = data;
    this.noNotifications = !data.length;
  }

  updateNotifications() {
    this.notification.fetchNotifications$('all').subscribe((result) => {
      this._notifications = this._notificationsDataSource.data = result;
      this.noNotifications = !result.length;
    });
  }

  onNotificationActionClick(event: Event, notification: Notification) {
    this.notification.notificationAction(notification);
    event.stopPropagation();
  }
}
