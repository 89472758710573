<div class="container" fxLayout="column" fxLayoutAlign="start center">
  <div class="content" fxLayout="column">
    <gc-logged-out-header></gc-logged-out-header>
    <div class="logo-backdrop" gdRow.lt-md="1" gdColumn="1 / span 4" fxHide.gt-sm>
      <button class="back-btn" mat-button type="button" routerLink="/landing">
        <mat-icon svgIcon="keyboard-backspace"></mat-icon>
        Back
      </button>
    </div>
    <gc-register-first-step (stepComplete)="firstStepComplete()" *ngIf="!stepsComplete[0]; else secondStep"></gc-register-first-step>
    <ng-template #secondStep>
      <gc-register-second-step (stepComplete)="secondStepComplete()"></gc-register-second-step>
    </ng-template>
  </div>
</div>
