import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'gc-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent implements OnInit {
  passwordForm: FormGroup;
  show = false;

  constructor(private readonly dialogRef: MatDialogRef<DeleteAccountComponent>, private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.required],
    });
  }

  submit() {
    this.dialogRef.close(this.passwordForm.value.password);
  }
}
