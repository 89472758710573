import * as moment from 'moment';

export interface ClinicalDocumentData {
  user_id: number;
  date: string;
  pdf?: string;
  doctor?: string;
  body?: string;
  title?: string;
}

export class ClinicalDocument {
  private readonly _userId: number;
  private readonly _date: moment.Moment;
  private _pdf: File;
  private readonly _doctor: string;
  private _pdfUrl: string;
  private readonly _title?: string;
  private _body?: string;
  private _textFile?: File;

  public readonly type: string;

  constructor(data: ClinicalDocumentData) {
    this._userId = data.user_id;
    this._date = moment(data.date);
    this._doctor = data.doctor;
    this._title = data.title;
    if (data.pdf) {
      this.resolvePDF(data.pdf);
    } else if (data.body) {
      this.resolveTextFile(data.body);
    }
  }

  public get userId() {
    return this._userId;
  }

  public get date() {
    return this._date;
  }

  public get pdf() {
    return this._pdf;
  }

  public get doctor() {
    return this._doctor;
  }

  public get pdfAsObjectUrl() {
    return this._pdfUrl;
  }

  public get title() {
    return this._title;
  }

  public get body() {
    return this._body;
  }

  public get label(): string {
    return null;
  }

  public get file() {
    return this.pdf || this._textFile;
  }

  private async resolvePDF(dataurl: string) {
    if (dataurl.split(',').length < 2) {
      dataurl = `data:application/pdf;base64,${dataurl}`;
    }
    const blob = await fetch(dataurl).then(async (res) => res.blob());
    const filename = `${this.type}-${this.sanitiseForFilename(this.doctor)}-${this.date.format('YYYY-MM-DD')}.pdf`;
    this._pdf = new File([blob], filename, {
      type: 'application/pdf',
    });
    this._pdfUrl = URL.createObjectURL(this._pdf);
  }

  private resolveTextFile(body: string) {
    this._body = body;
    const blob = new Blob([this._body]);
    const filename = `note-${this.sanitiseForFilename(this.title)}-${this.date.format('YYYY-MM-DD')}.txt`;
    this._textFile = new File([blob], filename, { type: 'text/plain' });
  }

  private sanitiseForFilename(text: string) {
    return text.replace(/[.']/g, '').replace(/\s+/g, '-').toLowerCase();
  }
}
