import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';

import { FamilyMember } from '@models/family-member.model';
import { Family, FamilyData } from '@models/family.model';
import { AlertService } from '@services/alert.service';
import { ErrorResponse } from '@models/responses.model';

@Injectable({
  providedIn: 'root',
})
export class FamilyService {
  familyCreated$ = new ReplaySubject<Family>(1);

  constructor(private readonly alert: AlertService, private readonly http: HttpClient) {
    this.familyCreated$.next(null);
  }

  createFamily(description: string) {
    this.http
      .post<FamilyData>('/api/families', { description })
      .pipe(
        catchError((err: unknown) => {
          this.alert.handleErrorDialog$(err, 'Family could not be created');
          return of(null);
        }),
        filter((data) => !!data),
        map((family) => new Family(family)),
      )
      .subscribe((family) => this.familyCreated$.next(family));
  }

  getFamilies$(getMedicalFamily = false): Observable<Family[]> {
    return this.http.get<FamilyData[]>('/api/families', { params: { medical: getMedicalFamily.toString() } }).pipe(
      catchError((err: unknown) => {
        if ((err as ErrorResponse).error === 'families/not-found') {
          return of([] as FamilyData[]);
        }
        this.alert.handleErrorDialog$(err, 'Families could not be fetched');
        return of([] as FamilyData[]);
      }),
      map((families: FamilyData[] | FamilyData) => {
        if (families instanceof Array) {
          return families.map((family) => new Family(family));
        }
        return [new Family(families)];
      }),
    );
  }

  inviteFamilyMember(familyId: number, email: string, role: string) {
    return this.http.post(`/api/family/${familyId}/invite`, { email, role }).pipe(
      catchError((err: unknown) => {
        this.alert.handleErrorDialog$(err, 'Family member could not be invited');
        return throwError(err);
      }),
    );
  }

  deleteFamilyMember(member: FamilyMember) {
    return this.http.delete(`/api/family/${member.familyId}/${member.id}`).pipe(
      catchError((err: unknown) => {
        this.alert.handleErrorDialog$(err, 'Family member could not be deleted');
        return throwError(err);
      }),
      tap(() => this.getFamilies$().pipe(take(1)).subscribe()),
    );
  }

  changeMemberRole(member: FamilyMember, role: string) {
    return this.http.patch(`/api/family/${member.familyId}/${member.id}`, { role }).pipe(
      catchError((err: unknown) => {
        this.alert.handleErrorDialog$(err, 'Family member role could not be changed');
        return throwError(err);
      }),
    );
  }
}
