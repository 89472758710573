import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TermsAndConditionsDialogComponent } from '@dialogs/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { User } from '@models/user.model';
import { DeleteAccountComponent } from '@modules/shared/dialogs/delete-account/delete-account.component';
import { AlertService } from '@services/alert.service';
import { SettingsService } from '@services/settings.service';
import { UserService } from '@services/user.service';
import { ReplaySubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'gc-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  profile$: ReplaySubject<User>;

  constructor(
    private readonly settings: SettingsService,
    private readonly alert: AlertService,
    private readonly dialog: MatDialog,
    private readonly userService: UserService,
  ) {}

  ngOnInit() {
    this.profile$ = this.userService.profile$;
  }

  onDeleteAccountClick() {
    this.alert
      .openDialog$(DeleteAccountComponent, {})
      .pipe(
        filter((password) => !!password),
        tap((password) => {
          this.settings.deleteAccount(password).subscribe((res) => {
            if (res) {
              this.alert.snackbar(res.message);
            }
          });
        }),
      )
      .subscribe();
  }

  showPrivacyPolicy() {
    this.dialog.open(TermsAndConditionsDialogComponent, {
      panelClass: 'privacy-policy-dialog',
      height: '95%',
      width: '95%',
    });
  }
}
