import { Notification, NotificationData } from './notification.model';

export class QuestionnaireNotification extends Notification {
  constructor(data: NotificationData) {
    super(data);
    const haveResponse: boolean | string | null = data.questionnaire ? data.questionnaire.complete : this.readDate;
    this.description = `Questionnaire from ${data?.questionnaire?.doctor}`;
    this.actionName = !!haveResponse ? 'Completed' : 'To Complete';
    this.actionRequired = !this.readDate;
  }
}
