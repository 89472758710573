import { Component, Input } from '@angular/core';
import { Doctor } from '@models/doctor.model';

@Component({
  selector: 'gc-doctor-actions',
  templateUrl: './doctor-actions.component.html',
  styleUrls: ['./doctor-actions.component.scss'],
})
export class DoctorActionsComponent {
  @Input() doctor: Doctor;
}
