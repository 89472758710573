import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { dialogMessage } from '@models/dialog-communication.model';

export interface MessageDialogData {
  message: string;
  detail?: string;
  tag?: string;
  confirmation: boolean;
  confirmText?: string;
  cancelText?: string;
  hideCancel?: boolean;
  confirmColour?: string;
  cancelColour?: string;
}

@Component({
  selector: 'gc-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent {
  private static readonly DIALOG_ICONS = {
    question: 'help-circle-outline',
    error: 'alert-circle-outline',
    confirm: 'check-circle-outline',
    info: 'information-outline',
    access: 'lock',
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: MessageDialogData, public dialogRef: MatDialogRef<MessageDialogComponent>) {}

  get confirmText() {
    return this.data.confirmText || 'Okay';
  }

  get cancelText() {
    return this.data.cancelText || 'Cancel';
  }

  get hideCancel() {
    return this.data.hideCancel || false;
  }

  get confirmColour() {
    return this.data.confirmColour;
  }

  get cancelColour() {
    return this.data.cancelColour;
  }

  get dialogIcon() {
    return MessageDialogComponent.DIALOG_ICONS[this.data.tag];
  }

  okayClick() {
    //we must use location API instead of Angular's built in Router because we never routed here
    //check if we are in the logged in payments page, or if we are in package payments
    if (
      window.location.search &&
      ((window.location.search.includes('hash') && window.location.pathname.includes('payments')) ||
        (window.location.pathname.includes('/p/') && window.location.search.includes('type=')))
    ) {
      window.parent.window.postMessage(dialogMessage.close, window.location.origin);
    }
  }
}
