import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';

// Used as reference: https://restfulapi.net/http-status-codes/
const HTTP_RETRY_CODES = {
  REQUEST_TIMEOUT: 408, // Server didn't get a complete request from client
  INTERNAL_SERVER_ERROR: 500, // Not the client's fault, allowed to try again
  SERVICE_UNAVAILABLE: 503, // Server is not ready yet
};

const isValidRetryCode = (statusCode: number, method) => Object.values(HTTP_RETRY_CODES).includes(statusCode) && method === 'GET';

const retryStrategy =
  (method: string, { maxAttempts = 2, scalingDuration = 500 } = {}) =>
  (attempts$: Observable<HttpErrorResponse>) =>
    attempts$.pipe(
      mergeMap((err, i) => (isValidRetryCode(err.status, method) && i < maxAttempts ? timer(i * scalingDuration) : throwError(err))),
    );

@Injectable()
export class ErrorRetryInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(retryWhen(retryStrategy(req.method)));
  }
}
