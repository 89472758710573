import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ItemData, Package } from '@models/package.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PackageService {
  private _identifier: string;
  constructor(public http: HttpClient) {}

  public set identifier(newIdentifier: string) {
    this._identifier = newIdentifier;
  }

  public get identifier() {
    return this._identifier;
  }

  getPackage$(uuid: string, identifier: string): Observable<Package[]> {
    if (identifier) {
      this._identifier = identifier;
    }
    return this.http.get<ItemData[]>(`/api/package/${uuid}/${this._identifier}`).pipe(map((item) => item.map((s) => new Package(s))));
  }
}
