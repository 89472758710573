import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, shareReplay } from 'rxjs/operators';
import { AlertService } from './alert.service';

interface Config {
  key: string;
  value: string;
  // ... other properties
}

export interface ConfigRegex {
  required?: boolean;
  pattern?: string;
}

export interface ConfigLocation {
  latitude?: number;
  longitude?: number;
}

export interface ConfigPrivateMedicalAid {
  number: string;
  option: {
    code: string;
    name: string;
  };
  scheme: {
    code: string;
    name: string;
  };
}
// 'location_json' is a key in the config table that stores the default location
// 'cell_validators_json' is a key in the config table that stores the cell number validators
// 'id_number_validators_json' is a key in the config table that stores the id number validators
// 'address_autocomplete' is a key in the config table that stores the google maps api key
// 'dependant_types_json' is a key in the config table that stores the dependant types
// 'private_medical_aid_json' is a key in the config table that stores the private medical aid
// 'rtc_url' is a key in the config table that stores the rtc url

@Injectable({
  providedIn: 'root', // Makes this service available application-wide
})
export class ConfigLocaleService {
  private configCache$: Observable<Config[]> | null = null;

  constructor(private readonly http: HttpClient, private readonly alert: AlertService) {}

  getConfig$(): Observable<Config[]> {
    if (!this.configCache$) {
      this.configCache$ = this.http.get<Config[]>('/api/config').pipe(
        shareReplay({ bufferSize: 1, refCount: true }), // Cache and share the result across subscribers
        catchError((error: unknown) => {
          this.alert.handleErrorDialog$(error, 'Unable to get config');
          this.configCache$ = null;
          return of(null);
        }),
      );
    }
    return this.configCache$;
  }

  getValueByKey$(key: string[]): Observable<{ [key: string]: string } | null> {
    return this.getConfig$().pipe(
      // Map to find the value for the given key, or null if not found
      map((config) => {
        const result: { [key: string]: string } = {};
        key.forEach((k) => {
          const item = config.find((c) => c.key === k);
          if (item) {
            result[k] = item.value;
          }
        });
        return result;
      }),
    );
  }
}
