import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { User } from '@models/user.model';
import { BetaTester, SettingsService } from '@services/settings.service';
import { AlertService } from '@services/alert.service';

@Component({
  selector: 'gc-beta-user',
  templateUrl: './beta-user.component.html',
  styleUrls: ['./beta-user.component.scss'],
})
export class BetaUserComponent implements OnInit, OnDestroy {
  @Input() profile: User;
  loadingSpinner = false;
  joinAsTester = false;
  betaTesterForm: FormGroup;
  subscription: Subscription | undefined;
  submitButtonText = 'OPT-IN';
  updateUser = false;
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly settingsService: SettingsService,
    private readonly alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.betaTesterForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      user_id: [null],
      name: [null, [Validators.required]],
      active: [true],
    });
    if (this.profile) {
      this.getBetaTester();
    }
  }

  getBetaTester() {
    this.loadingSpinner = true;
    this.subscription = this.settingsService.getBetaTester$(this.profile.id, this.profile.email).subscribe({
      next: (res: BetaTester) => {
        this.setBooleans(res);
      },
      error: (err: unknown) => {
        this.loadingSpinner = false;
        this.alertService.handleErrorDialog$(err, 'Unable to get beta tester');
      },
      complete: () => {
        this.loadingSpinner = false;
      },
    });
  }

  joinAsTesterToggle() {
    this.joinAsTester = !this.joinAsTester;
    this.betaTesterForm.get('active').setValue(this.joinAsTester);
    if (!this.betaTesterForm.get('active').value && this.updateUser) {
      this.onSubmit();
    }
  }

  onSubmit() {
    if (this.betaTesterForm.invalid) {
      this.alertService.messageDialog$('Invalid form', 'Please fill out the form correctly');
      return;
    }
    this.loadingSpinner = true;
    this.subscription = this.settingsService.postBetaTester$(this.betaTesterForm.value).subscribe({
      next: (res: BetaTester) => {
        this.setBooleans(res);
      },
      error: (err: unknown) => {
        this.alertService.handleErrorDialog$(err, 'Unable to post beta tester');
        this.loadingSpinner = false;
        this.setBooleans(null);
      },
      complete: () => {
        this.loadingSpinner = false;
      },
    });
  }

  setBooleans(betaTester: BetaTester) {
    if (!betaTester) {
      this.betaTesterForm.patchValue({
        email: this.profile.email,
        user_id: this.profile.id,
        name: this.profile.name,
        active: true,
      });
    } else if (!betaTester.active) {
      this.updateUser = false;
      this.joinAsTester = false;
      this.submitButtonText = 'Re-Opt-In';
      this.betaTesterForm.patchValue(betaTester);
    } else {
      this.updateUser = true;
      this.joinAsTester = true;
      this.submitButtonText = 'Update';
      this.betaTesterForm.patchValue(betaTester);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
