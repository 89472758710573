import { ElementRef, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export interface ChatMessage {
  sender: string;
  message: string;
  timestamp: string;
}

@Injectable({
  providedIn: 'root',
})
export abstract class WebRTCService {
  abstract messages$: ReplaySubject<ChatMessage>;
  abstract readonly ready: boolean;
  abstract connect(remoteVideos: ElementRef<HTMLElement>, localVideo: ElementRef<HTMLVideoElement>): void;
  abstract disconnect(): void;
  abstract sendMessage(message: string): void;
  abstract joinRoom(roomUuid: string): void;
  abstract leaveRoom(): void;
}
