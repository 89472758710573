<div class="container" fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="center center">
  <div class="header-buttons" fxLayout="row" fxLayoutAlign="space-between">
    <button mat-stroked-button color="accent" type="button" (click)="feedback()">
      <mat-icon svgIcon="comment-quote"></mat-icon>
      Feedback
    </button>
    <button mat-stroked-button color="accent" type="accent" (click)="logout()">
      <mat-icon svgIcon="lock"></mat-icon>
      Logout
    </button>
  </div>
  <mat-card *ngIf="profile$ | async as profile; else loading">
    <mat-card-header>
      <mat-card-title fxLayout="column" fxLayoutAlign="start">
        <span>Welcome, {{ profile.name }}!</span>
      </mat-card-title>
      <mat-card-subtitle>We just need a few more details to complete your registration</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <form fxLayout="column" fxLayoutAlign="start stretch" [formGroup]="profileForm" (submit)="submit()">
        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input matInput type="text" autocomplete="given-name" formControlName="name" />
          <mat-error>Name is required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Surname</mat-label>
          <input matInput type="text" autocomplete="family-name" formControlName="surname" />
          <mat-error>Surname is required</mat-error>
        </mat-form-field>
        <gc-input-field
          formControlName="idnumber"
          label="{{ 'ID number ' + (idRequired ? '*' : '') }}"
          [errorMap]="{ pattern: 'Please enter a valid RSA ID number' }"
          [error]="true"
          (change)="populateDobAndGender()"
          class="idnumber-field"
          *ngIf="!idHidden"
        ></gc-input-field>
        <mat-form-field>
          <mat-label>Date of Birth</mat-label>
          <input matInput type="text" formControlName="dob" autocomplete="bday" [matDatepicker]="dobPicker" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
          <mat-datepicker #dobPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label class="mat-label-text">Gender</mat-label>
          <mat-select formControlName="gender" disableOptionCentering>
            <mat-option *ngFor="let gender of genderOptions" [value]="gender">
              {{ gender }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Mobile number</mat-label>
          <input matInput type="tel" autocomplete="mobile" formControlName="cellnr" />
          <mat-error *ngIf="profileForm.get('cellnr').hasError('required')">Mobile number is required</mat-error>
          <mat-error *ngIf="profileForm.get('cellnr').hasError('pattern')">Please enter a valid phone number</mat-error>
        </mat-form-field>
        <div fxLayout="column" fxLayoutGap="12px">
          <gc-terms-and-conditions class="terms-and-conditions" (acceptTerms)="acceptTerms = $event"></gc-terms-and-conditions>
          <button mat-raised-button color="primary" type="submit" [disabled]="profileForm.invalid || !acceptTerms">Register</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
  <ng-template #loading>
    <mat-spinner color="accent"></mat-spinner>
    <h1 class="mat-heading">Loading profile...</h1>
  </ng-template>
</div>
