export const IDNUMBER_REGEX = /^\d{2}[0-1][0-9][0-3]\d{5}[0-1]\d{2}$/;
export const CELLNR_REGEX = /^(\+[1-9][0-9]{0,2} ?)?[0-9]{6,14}$/;
export const DATE_FORMAT_REGEX = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/; // yyyy-mm-dd && yyyy-m-d

export const NON_CONFORMING_TITLES = ['Mx.'];

export const TITLES = ['Mr.', 'Mrs.', 'Miss.', 'Ms.', 'Dr.', 'Prof.', 'Mast.', 'Rev.', 'Ds.'].concat(NON_CONFORMING_TITLES);

export const GENDERS = ['Female', 'Male', 'Other'];

// These IDTYPES are not used but should be used as reference.
// Goodxweb's ID types are defined as follows:
export const IDTYPES = {
  0: 'SA ID',
  1: 'Passport',
  2: 'Other',
};

export const LANGUAGES = [
  'English',
  'Afrikaans',
  'isiZulu',
  'isiXhosa',
  'Tsonga',
  'Tswana',
  'Southern Sotho',
  'Swati',
  'Venda',
  'Ndebele',
  'Northern Sotho',
];

export const DEPENDANT_TYPES = [
  'Main Member',
  'Child',
  'Spouse',
  'Adult dependent',
  'Stepchild',
  'Adopted child',
  'Foster child',
  'Newborn',
  'Parent',
];

export interface FamilyRole {
  name: string;
  assignable: boolean;
  hint?: string;
  icon?: string;
}

export const FAMILY_ROLES: FamilyRole[] = [
  { name: 'stub', assignable: false, icon: 'account-supervisor' },
  {
    name: 'normal',
    assignable: true,
    hint: `The <strong>Normal</strong> role is the base role in a family.
      Accounts with the Normal role cannot add or remove members from the family.`,
  },
  {
    name: 'admin',
    assignable: true,
    hint: `The <strong>Admin</strong> role can add or remove members from the family.
      Admins cannot remove the owner or disband the Family group.`,
    icon: 'key',
  },
  { name: 'owner', assignable: false, icon: 'crown' },
];

export const REQUIRED_FIELDS_MISSING_MESSAGE = 'One or more required fields are not filled in.';

export const KIOSKTIMEOUT = 300000; // 5 mins = 5min*60seconds*1000

export const FAMILY_HISTORY = [
  'Arthritis (Osteoarthritis)',
  'Arthritis (Rheumatoid)',
  'Asthma (Bronchial)',
  'Asthma (General)',
  'Autoimmune Disease',
  'Blood Disorder',
  'Cancer',
  'Diabetes Mellitus Type 1',
  'Diabetes Mellitus Type 2',
  'Dementia (onset <60 years)',
  'Depression',
  'Gastrointestinal Disease',
  'Genetic Disorder',
  'Heart Disease',
  'Hearing Loss (onset <50 years)',
  'High Cholesterol/Hyperlipidaemia',
  'Hypertension',
  'Kidney Disease',
  'Neurological Disorders',
  'Osteoporosis',
  'Parkinsons (onset <50 years)',
  'Pulmonary Tuberculosis',
  'Psychiatric Illness',
  'Seizures/Epilepsy',
  'Stroke',
  'Thyroid Disease',
  'Visual Disorders',
  'Others',
];

export const NATURAL_INGREDIENTS = [
  { code: '32', description: 'Alchohol - Alcoholic Beverages' },
  { code: '33', description: 'Cheese' },
  { code: '34', description: 'Chocolate' },
  { code: '35', description: 'Citrus Fruits' },
  { code: '36', description: 'Cockroach Debri' },
  { code: '37', description: 'Coffee' },
  { code: '38', description: 'Corn' },
  { code: '1', description: 'Dander' },
  { code: '5', description: 'Dust mite Droppings' },
  { code: '6', description: 'Eggs' },
  { code: '7', description: 'Fish' },
  { code: '9', description: 'Gluten' },
  { code: '8', description: 'Grasses & Flowers' },
  { code: '27', description: 'House Dust - Fabric Fibres' },
  { code: '26', description: 'House Dust - Human & Animal Hair' },
  { code: '25', description: 'House Dust - Human & Animal Skin' },
  { code: '28', description: 'House Dust - Insect Parts' },
  { code: '24', description: 'House Dust - Plant materials' },
  { code: '31', description: 'House Dust - Soil' },
  { code: '29', description: 'Insect bites - Honey Bee' },
  { code: '30', description: 'Insect bites - Spiders' },
  { code: '3', description: 'Latex' },
  { code: '10', description: 'Milk' },
  { code: '11', description: 'Mold & Mildew' },
  { code: '12', description: 'Nitrates' },
  { code: '13', description: 'Nuts - Peanut' },
  { code: '21', description: 'Nuts - Tree nut' },
  { code: '2', description: 'Plasters' },
  { code: '14', description: 'Pollens' },
  { code: '15', description: 'Shellfish' },
  { code: '16', description: 'Soybeans' },
  { code: '17', description: 'Spores' },
  { code: '18', description: 'Sulphates' },
  { code: '19', description: 'Tobacco Smoke' },
  { code: '20', description: 'Tomatoes' },
  { code: '22', description: 'Wheat' },
  { code: '23', description: 'Yeast' },
  { code: '4', description: 'Other' },
];

// default values for the form is RSA
export const ID_CONFIG = {
  hidden: false,
  required: false,
  pattern: '^\\d{2}[0-1][0-9][0-3]\\d{5}[0-1]\\d{2}$',
};
