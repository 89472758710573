import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DisplayService {
  isMobile$: BehaviorSubject<boolean>;
  private readonly renderer: Renderer2;

  constructor(public mediaObserver: MediaObserver, private readonly rendererFactory: RendererFactory2) {
    this.isMobile$ = new BehaviorSubject(false);
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.mediaObserver
      .asObservable()
      .pipe(
        map((changes) => changes.some((c) => ['lt-md'].includes(c.mqAlias))),
        distinctUntilChanged(),
      )
      .subscribe(this.isMobile$);
  }

  changeTheme(primary: string, secondary: string) {
    const root = document.documentElement.querySelector('body');
    const theme = `--primary-color:${primary};--accent-color:${secondary}`;
    this.renderer.setAttribute(root, 'style', theme);
  }

  resetTheme() {
    const root = document.documentElement.querySelector('body');
    this.renderer.removeAttribute(root, 'style');
  }
}
