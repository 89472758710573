import { now } from 'moment';
import { Notification, NotificationData } from './notification.model';

export class BookingNotification extends Notification {
  constructor(data: NotificationData) {
    super(data);

    this.actionName = 'View Booking';
    this.actionRequired = this.booking.date.isBefore(now());
    this.description = [
      'You',
      this.booking.date.isAfter(now()) ? 'have an upcoming' : 'had a',
      'booking with',
      this.booking.doctor.fullName,
      'at',
      this.booking.date.format('HH:mm on dddd, D MMMM'),
    ].join(' ');
  }
}
