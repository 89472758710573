<div class="container" fxFlex="grow" fxLayout="column" fxLayoutAlign.lt-md="start center" fxLayoutAlign.gt-sm="center center">
  <div gdColumns="repeat(4, 1fr)" gdColumns.gt-sm="auto repeat(2, 408px) auto" gdRows="repeat(4, min-content)">
    <div class="logo-backdrop" gdRow="1 / span 1" gdColumn="1 / span 4" fxHide.gt-sm></div>
    <div class="mobile-page-header" gdRow="1" gdColumn="1 / span 4" fxHide.gt-sm>
      <button mat-button type="button" (click)="onBackClick()">
        <mat-icon svgIcon="arrow-left"></mat-icon>
      </button>
      <span>Reset your password</span>
    </div>
    <div class="forgot-password-container" gdRow="2" gdColumn="1 / span 4" gdColumn.gt-sm="2 / span 2" fxLayout="row">
      <gc-login-splash class="login-splash-password" fxHide.lt-md></gc-login-splash>
      <gc-reset-password></gc-reset-password>
    </div>
  </div>
</div>
