import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AboutService } from '@services/about.service';

@Component({
  selector: 'gc-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
})
export class AboutDialogComponent implements OnInit {
  version: string;
  date: Date;
  constructor(public dialogRef: MatDialogRef<AboutDialogComponent>, public about: AboutService) {}

  ngOnInit() {
    this.version = this.about.version();
    this.date = this.about.date();
  }
}
