import { Component, Input } from '@angular/core';
import { Payment } from '@models/account.model';

@Component({
  selector: 'gc-payment-display-card',
  templateUrl: './payment-display-card.component.html',
  styleUrls: ['./payment-display-card.component.scss'],
})
export class PaymentDisplayCardComponent {
  @Input()
  payment: Payment;
}
