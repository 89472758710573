import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'gc-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  currentStep = 0;
  stepsComplete = [false, false];
  constructor(
    private readonly auth: AuthService,
    private readonly router: Router,
    public location: Location,
    private readonly cd: ChangeDetectorRef,
  ) {}

  get header() {
    const headers = ['Step 1 of 2', 'Step 2 of 2'];
    return headers[this.currentStep];
  }

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      this.stepsComplete[0] = true;
      this.currentStep = 1;
    }
  }

  firstStepComplete() {
    this.stepsComplete[0] = true;
    this.currentStep++;
    this.cd.detectChanges();
  }

  secondStepComplete() {
    this.stepsComplete[1] = true;
    this.router.navigate(['/']);
  }
}
