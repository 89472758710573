import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { filter, take, tap } from 'rxjs/operators';

@Component({
  selector: 'gc-block-email-change',
  templateUrl: './block-email-change.component.html',
  styleUrls: ['./block-email-change.component.scss'],
})
export class BlockEmailChangeComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly alertService: AlertService,
  ) {}

  ngOnInit() {
    this.route.queryParamMap
      .pipe(
        take(1),
        filter((params) => !!params.get('code')),
        tap((params) => {
          const code = params.get('code');
          this.authService.blockEmailChange(code).subscribe(
            () => {
              this.alertService.snackbar('Email change requested blocked', 'success');
            },
            (err: unknown) => {
              this.alertService.handleErrorDialog$(err, 'Unable to cancel email change');
            },
          );
        }),
      )
      .subscribe();
  }

  continue() {
    this.router.navigate(['/login']);
  }
}
