import { FamilyMember, FamilyMemberData } from './family-member.model';

export interface FamilyData {
  id: number;
  description: string;
  members: FamilyMemberData[];
}

export class Family {
  readonly id: number;
  readonly description: string;
  readonly members: FamilyMember[];

  constructor(data: FamilyData) {
    this.id = data.id;
    this.description = data.description;
    this.members = data.members.map((m) => new FamilyMember(m));
  }

  get owner() {
    return this.members.find((m) => m.role === 'owner');
  }

  get admins() {
    return this.members.filter((m) => m.role === 'admin');
  }

  get normals() {
    return this.members.filter((m) => m.role === 'normal');
  }

  get stubs() {
    return this.members.filter((m) => m.role === 'stub');
  }
}
