<div class="terms-conditions" mat-dialog-container>
  <div fxLayout="row" class="heading">
    <div fxFlex="grow">
      <h1 mat-dialog-title>
        <span>myGC</span>
        Terms & Conditions
      </h1>
    </div>
  </div>
  <div mat-dialog-content>
    <h3>Introduction</h3>
    <p>
      myGrandCentral ("myGC") has been created specifically with you in mind. We know how you hate to complete paperwork and wait in lines
      to have it processed, especially when you have more serious concerns on your mind! By using our service, you can complete your
      profiled medical and wellness information once-off, while we securely store it on your behalf, easily accessible to you and it will
      only be shared with the medical service providers or other people you choose to share it with.
    </p>
    <p>
      It can also mean you don't have to carry medical reports with you to doctors, have online access to your prescriptions, referrals,
      sick notes, year-end medical tax certificate and more. To be able to use this service, however, you and myGC have to agree on how we
      are going to co-operate and what myGC may do to make this possible. You need to know how important your privacy and security of
      information is to us and agree to the secure ways in which it may be shared on your behalf.
    </p>
    <p>
      To this end, we request you to properly read these terms and conditions of the use of the myGC service. When reference is made to one
      gender, it applies equally to all genders. To assist in making matters clearer, myGC will be attaching the following meaning to the
      words used in this Terms and Conditions document ("T&C"):
    </p>
    <ol>
      <li>
        <strong>Profile:</strong>
        Your personal digital identity profile containing your details and/or the details of your dependants on your medical aid (if
        applicable). This will include separate pockets of information as shown on the profile page on the website. These may include
        identity, medical aid and address information for billing purposes; medical/specialist reports, referral letters, scripts and sick
        notes etc.
      </li>
      <li>
        <strong>Username and password:</strong>
        Your secret codes and password as may be required from time to time to access our App and your profile.
      </li>
      <li>
        <strong>App:</strong>
        The myGC website and mobile application for your device through which you can access your profile to add, amend or share information
        to the extent that you wish to do so.
      </li>
      <li>
        <strong>Updates:</strong>
        The updates, improvements, additions and expansions of the App from time to time aimed at improving the service of myGC and your
        user experience.
      </li>
      <li>
        <strong>Device:</strong>
        The smartphone, tablet, computer or similar through which you access the App.
      </li>
      <li>
        <strong>Intellectual Property:</strong>
        This includes, but is not limited to, all designs, inventions, specifications, trademarks, patents, trade names, copyright,
        (including copyright for designs, logos, slogans), multimedia work, software and/or programs, code in any form, developmental notes
        & designs, goodwill, know-how, ideas, concepts, methods and/or techniques, trade secrets, business and/or marketing information or
        data, databases and/or rights thereto, and/or any other information confidential to myGC or any other intellectual property or
        similar rights.
      </li>
      <li>
        <strong>Intellectual Property Rights:</strong>
        All rights in and to Intellectual Property.
      </li>
      <li>
        <strong>ISP:</strong>
        Internet Service Provider, which provides one with access to the internet.
      </li>
      <li>
        <strong>MSP:</strong>
        Medical Service Provider, which is any party or person offering medical or related services to the user, which may include medical
        doctors, dentists, therapists, specialist, practices, laboratories and auxiliary medical / supporting services.
      </li>
      <li>
        <strong>User:</strong>
        You, the person who elects to make use of the facility and service offered you and your dependants by myGC.
      </li>
      <li>
        <strong>Service:</strong>
        The service rendered to you by myGC through the App to collect, store, manage and only to the extent that you require and permit,
        share your information with MSP or third parties of your choice, and assist with the processing, delivery and payment follow-ups in
        respect of invoices for medical services rendered by the MSP to the user.
      </li>
      <li>
        <strong>Territory</strong>
        At present, this service is only available in South Africa and with participating MSPs.
      </li>
    </ol>
    <h3>Free use of the service & additional services</h3>
    <p>
      This service is offered to the user totally free of charge and no license fee or otherwise is payable to use it. myGC will from time
      to time give you the option to choose any of our new services being developed. myGC also gives you a platform and link with further
      information about matters which may be important to you, such as DNRs (Do Not Resuscitate), living wills and organ donor membership.
    </p>
    <h3>Warranties by user</h3>
    <p>The User warrants that:</p>
    <ol>
      <li>The information disclosed to myGC is accurate and true;</li>
      <li>The user has read, understood and accepted the T&C before entering into this agreement;</li>
      <li>
        The user will keep secure and confidential all usernames, passwords or other access codes and not disclose these to any third party.
        This includes taking care when entering such information in a manner that cannot be easily observed and taking particular care when
        using a device not personal to the User. It also means following correct logging in and out processes as provided for in the App.
      </li>
      <li>
        The user warrants that the user has read and accepted the standard terms and conditions of the MSP to whom information is shared in
        terms hereof.
      </li>
    </ol>
    <h3 class="red">App security</h3>
    <ol>
      <li>
        The user MUST keep access codes, usernames and passwords secure. It will be assumed that someone using any of these, has the
        necessary permission to do so unless you inform us otherwise the moment you suspect a breach of security on your side.
      </li>
      <li>You must make sure that you log out of any activity on the App once you are done checking, adding or amending information.</li>
      <li>
        It is our highest priority to ensure safe online services to you. myGC uses encryption at international standards to protect any use
        of the App and information associated with it. Only the authorised employees and agents of myGC can access information related to
        the App.
      </li>
    </ol>
    <h3>Software and hardware</h3>
    <ol>
      <li>
        You must have your own access to the device to use with the App. This is not provided to you by myGC. You must ensure that your
        device and the software or hardware needed to use the App, is suitable for the App, otherwise it may not work properly or pose a
        security risk. Please check the myGC website for the specifications needed for your device to effectively use the App.
      </li>
      <li>
        You must ensure that software used on the device, is properly licensed and any such agreement, excluding that for the specific App,
        will be between you and the licensor of the software. You hereby indemnify myGC against any breach of such software license.
      </li>
      <li>
        Any claim relating to the license to the App, possession or use of the App, is between you and myGC. This would include (but not be
        limited to) product (App) liability claims, any claim that the App fails to conform to any legal or regulatory requirement that may
        be applicable or any claims relating to consumer protection liability.
      </li>
    </ol>
    <h3>Disclaimer</h3>
    <p>
      myGC (including its employees, consultants, agents, officials or related persons) is not liable for any losses, damages or claims of
      any nature whatsoever resulting from:
    </p>
    <ol>
      <li>Incorrect or incomplete information provided by the user;</li>
      <li>
        Any interruption in the use of the App resulting from factors beyond the control of myGC, such as network coverage, continuity or
        availability, ISP availability, or capability or capacity of your device;
      </li>
      <li>
        Use of your access codes, username or password by any third party or subsequent access to your information where such use was not
        due to a breach of the myGC internal security;
      </li>
      <li>
        Any technical or other problem (including interruption, malfunction, downtime or other failure affecting the App, database or part
        thereof, or third party system for any reason;
      </li>
      <li>
        Any direct or indirect loss of or damage to personal information or data as a result of technical problems, power failures or
        interruptions, unlawful acts, any harmful software/computer programs/ viruses on the system or your device, or any negligence on the
        part of the user;
      </li>
      <li>
        Any factors beyond our control and/or resulting in a user's inability to access or use the App, including failures or problems
        affecting services by any other party such as ISP, telecommunications service providers, local or other authorities, utility
        suppliers etc;
      </li>
      <li>
        There may sometimes be information on the App or links to other service providers for the convenience of the user. However, myGC
        accepts no responsibility or liability for any products, services or websites advertised on or in any manner linked to the myGC
        website, other than that of myGC and this App itself;
      </li>
      <li>
        The user agrees and confirms that the default T&C applicable to MSP for the use of the App, will likewise be applicable and valid in
        respect of the user.
      </li>
    </ol>
    <h3>Data protection</h3>
    <ol>
      <li>
        The Protection of Personal Information Act (POPI), No 4 of 2013 promotes the protection of personal information by public and
        private bodies.
      </li>
      <li>
        For the App to work and to be able to administer your profile, we need to collect and process the personal information you provided
        to us.
      </li>
      <li>
        You hereby, in terms of POPI, consent to disclose this information to myGC, that myGC and its employees, agents or officials may use
        and process such information for the purpose of rendering the service, and may disclose such information to the extent that you
        permit to for example MSP or third parties of your choice.
      </li>
      <li>
        You further consent that generic information, not in any manner personally identifiable with you, may be used for statistical and
        research purposes.
      </li>
      <li>
        An MSP that contracts with myGC for purposes of the service and having access to your information with your permission, have to
        agree to comply with strict privacy policies and undertakes to ensure any employee, official agent or entity that deals with such
        information on behalf of the MSP, is bound by the same confidentiality policies.
      </li>
      <li>
        myGC undertakes to use its best endeavours and apply the highest standards of security as may be relevant in the industry to
        information of this nature, to ensure the safety, security and confidentiality of any information submitted to it by the user or MSP
        relating to the user.
      </li>
      <li>
        myGC will only send such messages, whether by text (SMS), e-mail or otherwise to you as may be necessary to effectively render the
        service to you, which may include information about updates or other requirements. Your information will not, unless you consent
        thereto, be provided to any third party for purposes of marketing, advertising or otherwise. If you give such consent, it may be
        withdrawn at any time by informing myGC accordingly.
      </li>
      <li>
        The user will at all times (except in the event of circumstances beyond the control of myGC such as interruption in ISP services)
        have access to and be able to determine the nature and extent of any information pertaining to the user held by and on behalf of the
        user. Should there be any uncertainty or queries in this regard, the user will be able to contact myGC at the web address at the
        hours of business as indicated there.
      </li>
      <li>
        The user must pay attention to and take note of requests for elections to be made or permissions to be granted from time to time
        within the app and make such elections or give such permissions with due attention to what is being requested.
      </li>
    </ol>
    <h3>Breach</h3>
    <p>
      Should a user at any time abuse the service or App in such a way as to compromise its integrity, cause harm to myGC, MSP or others,
      myGC may give notice to the user to remedy the breach within 7 days, or where circumstances justify it, terminate its service to the
      user with immediate effect.
    </p>
    <h3>intellectual property</h3>
    <ol>
      <li>
        myGC holds and retains all intellectual property rights in and to, or sent to, from or through the App, all content thereof
        (including but not limited to source codes, all proprietary information, copyrights, trademarks, concepts & designs), subject only
        to such rights held by third parties or as indicated otherwise by myGC.
      </li>
      <li>
        The user is hereby granted the non-exclusive, non-transferrable, non-assignable license to use the App. The user may also not
        sub-license any part or the whole of such license granted by the myGC or any other intellectual property rights of myGC. This
        license is for the sole purpose as set out in this T&C and may not be used for any other reason or purpose. The same will apply to
        any T&C which may be added or amended through updates to the App and applicable to the user.
      </li>
      <li>
        The license granted to the user in terms hereof will be valid from the time the App is installed until such time as it is
        uninstalled or permission to use it has been withdrawn in accordance with this T&C, at which point the user may no longer use the
        App.
      </li>
      <li>
        The user may not engage in or permit, the copying, distribution, adaptation, modification, reverse engineering, deconstruction or
        any other interference or reproduction of the App, any content or portion thereof, or in any manner infringe on the intellectual
        property rights of myGC or any third party relating thereto or create/ permit the impression that you have any rights thereto.
      </li>
      <li>
        The user agrees not to copy, attempt to copy or create any similar application for a similar purpose in competition with myGC and/or
        the App, or create any material or application which may be confused or associated with the App by way of content, appearance or
        purpose.
      </li>
    </ol>
    <h3>Indemnity</h3>
    <ol>
      <li>
        The user indemnifies and holds harmless myGC, its employees, officials, directors, agents or associates against any actions, claims,
        damages, losses, expenses or related liabilities suffered or incurred or instituted by third parties against myGC as a result of any
        unauthorised or improper use of the App by the user or any infringement on any intellectual property rights as a result of
        unauthorised or improper use of the App.
      </li>
      <li>
        The user indemnifies and holds harmless myGC against any unlawful infringement of the user's rights by any MSP or related
        person/party through or associated with the use of the App.
      </li>
      <li>
        The user indemnifies, holds harmless and will reimburse myGC for any losses, damages, or costs suffered or occasioned by incorrect
        banking information provided by the user to myGC for purposes of processing invoices, monitoring and following up on payment
        thereof.
      </li>
      <li>
        The user indemnifies myGC against any loss, claim or injury resulting from the use of the Internet in the dealings with myGC and
        carries all risks involved in the use of Internet applications. These risks include the risks of exposure of sensitive or
        confidential information to persons for whom it was not intended, hacking, viruses, exposure to contaminated files and objectionable
        material, spamming, electronic fraud and other security risks. While guaranteeing your information will be safely kept with myGC,
        myGC shall not be liable for any hacking of your information through the internet, intrusion on your personal information or for any
        unauthorised access to the personal information or data stored or communicated by you or any other person using the services of
        myGC.
      </li>
    </ol>
    <h3>General</h3>
    <ol>
      <li>
        The laws of the Republic of South Africa will be applicable to any and all disputes resulting from the interpretation or application
        of this T&C or in any manner relating to the use of the App.
      </li>
      <li>
        The T&C relating to the App may be amended and/or updated with updates to the App. It will be the responsibility of the user to from
        time to time re-familiarize himself with the current T&C. The user will periodically and in the event of any changes to the T&C, be
        requested to accept the updated T&C in the App, the preferred method of communication with the user as selected by the user.
      </li>
      <li>The user should report any misuse or abuse of the App that comes to the user's attention to myGC immediately.</li>
      <li>
        myGC is subject to and subscribes to the provisions of the Electronic Communications and Transactions Act 25 of 2002 (section 51)
        with regard to the protection of the user's personal information, as well as the POPI Act. The wording of the provisions below have
        been adapted and expanded to be applicable to the person's situation as a user, but the full import and effect of the provisions
        will apply to the user;
        <ol type="a">
          <li>
            myGC may only use the user's personal information to compile generic profiles for purposes of statistics. These generic profiles
            may be used in conjunction with other statistical data and traded freely, with the proviso that none of the information can be
            linked to any particular user by a third party. This means that myGC will not disclose any of the user's personal information or
            data to any third party in the absence of a legal requirement or specific authorization from the users, other than provided for
            in this agreement.
          </li>
        </ol>
      </li>
      <li>
        Any personal information that can be linked to the user, can only be disclosed with express permission from the user, which the user
        will give by selecting an option to that effect on the website or mobile application; This permission may be withdrawn by the user
        for any information going forward or any use of information already given going forward, at any time.
      </li>
      <li>The user will be alerted to any information so disclosed or used.</li>
    </ol>
    <h3>Record keeping</h3>
    <ol>
      <li>
        myGC will keep a record of all your personalised information for the duration that such data is used and for a period of at least
        one year thereafter.
      </li>
      <li>
        This information retained will include the personalised data and the specific purpose for which the personalised data was collected
        as well as any third party to whom the user's personalised data was disclosed, of the date on which and the purpose for which it was
        disclosed.
      </li>
      <li>
        One year after the user's membership with myGC has ended, all the user's personalised information will be deleted or destroyed.
      </li>
      <li>
        Only personal data that is needed for a lawful purpose, will be disclosed in terms of this agreement and it will only for the
        purpose as disclosed to the user. No personalised data for which the user has not given express permission will be disclosed by the
        user unless accordingly permitted or required by law.
      </li>
    </ol>

    <h3>Refund policy</h3>
    <ol>
      <li>
        If the Client wants to cancel the Agreement before configuration and/or installation and/or training has commenced, all payments
        will be refunded to the Client. If configuration and/or installation and/or training has commenced by the time the Client cancels
        the Agreement, no refunds will be paid to the Client to cover GoodX’s costs.
      </li>
      <li>No reversals of credit card payments will be allowed after services were provided to the Client.</li>
    </ol>
    <h3>Cancellation policy</h3>
    <p>The Agreement can be cancelled at any time with one calendar month’s notice, unless otherwise agreed upon in writing</p>
    <h3>Transaction currency</h3>
    <p>The transaction currency used by GoodX is South African Rand (ZAR).</p>
    <h3>Country of domicile</h3>
    <p>Republic of South Africa.</p>
  </div>
  <div fxLayoutAlign="center center" class="close">
    <button mat-button color="primary" type="button" mat-dialog-close>
      <mat-icon svgIcon="close"></mat-icon>
      Close
    </button>
  </div>
</div>
