import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BookingNotification } from '@models/notifications/booking-notification.model';
import { DemographicNotification } from '@models/notifications/demographic-request-notification.model';
import { ConsentFormNotification } from '@models/notifications/consent-form-notification.model';
import { DocumentNotification } from '@models/notifications/document-notification.model';
import { Notification, NotificationData } from '@models/notifications/notification.model';
import { PaymentNotification } from '@models/notifications/payment-notification.model';
import { QuestionnaireNotification } from '@models/notifications/questionnaire-notification.model';
import { TelemedNotification } from '@models/notifications/telemed-notification.model';
import { MedicalHistoryNotification } from '@models/notifications/medical-history-request-notification.model';
import { combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { AuthService } from './auth.service';
import { WebSocketService } from './websocket.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notifications$ = new ReplaySubject<Notification[]>(1);
  amountOfUnreadNotifications$ = new ReplaySubject<number>(1);
  refresh$: Subject<void>;

  constructor(
    private readonly alert: AlertService,
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly router: Router,
    private readonly websocket: WebSocketService,
  ) {
    this.refresh$ = new Subject<void>();
    combineLatest([this.refresh$, this.auth.authenticated$.pipe(distinctUntilChanged(), startWith(this.auth.isAuthenticated()))])
      .pipe(
        map(([_, authenticated]) => authenticated),
        tap((authenticated) => {
          if (authenticated) {
            this.checkUnreadNotifications$().subscribe((amount) => {
              this.amountOfUnreadNotifications$.next(amount);
            });
            this.fetchNotifications$().subscribe((notifs) => {
              this.notifications$.next(notifs);
            });
          } else {
            this.notifications$.next([]);
            this.amountOfUnreadNotifications$.next(0);
          }
        }),
      )
      .subscribe();
    this.refresh$.next();

    this.websocket
      .waitUntilReady()
      .pipe(switchMap(() => this.websocket.listen$('notifications')))
      .subscribe(() => {
        this.refresh$.next();
      });
  }

  // Notifications are fetched from the database as is.
  // In most screens a more informative description, icon or date is required, or even some extra properties
  // derived from state and/or other properties on the notification.
  // For this reason there is the mapping function, declared on the NotificationService to keep relevance.
  // So if you do not want the raw data where you call the getNotifications method, just map it on the screen
  // using the mapping function.
  private frontendMapping(n: NotificationData): Notification {
    n.icon = Notification.types[n.type].icon;
    // Only some notifs have mapped classes.
    switch (n.type) {
      case 'telemed': {
        return new TelemedNotification(n);
      }
      case 'questionnaire': {
        return new QuestionnaireNotification(n);
      }
      case 'consent-form': {
        return new ConsentFormNotification(n);
      }
      case 'document': {
        return new DocumentNotification(n);
      }
      case 'payment': {
        return new PaymentNotification(n);
      }
      case 'booking': {
        return new BookingNotification(n);
      }
      case 'demographic-request': {
        return new DemographicNotification(n);
      }
      case 'medical-history': {
        return new MedicalHistoryNotification(n);
      }
      default:
        return new Notification(n);
    }
  }
  private checkUnreadNotifications$(): Observable<number> {
    return this.http.get<{ unread_notifications: number }>('/api/notifications/unread').pipe(
      catchError(() => of([] as Notification[])),
      map((obj: { unread_notifications: number }) => obj.unread_notifications),
    );
  }

  fetchNotifications$(state = 'unread'): Observable<Notification[]> {
    return this.http.get<NotificationData[]>('/api/notifications', { params: { state } }).pipe(
      catchError((err: unknown) => {
        this.alert.handleErrorDialog$(err, 'Error fetching notifications');
        return of([] as Notification[]);
      }),
      map((notifications: NotificationData[]) => notifications.map((notification) => this.frontendMapping(notification))),
    );
  }

  markAsRead$(notification: Notification): Observable<void> {
    return this.http.patch<{ result: boolean }>(`/api/notifications/${notification.id}`, { read: true }).pipe(
      map((obj: { result: boolean }) => {
        if (obj.result) {
          this.checkUnreadNotifications$().subscribe((amount) => {
            this.amountOfUnreadNotifications$.next(amount);
          });
        }
      }),
    );
  }

  /**
   * This is what takes you to the notification action, like opening a payment or opening a telemed room
   */
  notificationAction(notification: Notification) {
    this.markAsRead$(notification).pipe(take(1)).subscribe();
    const path: string = Notification.types[notification.type].path;

    const params = Object.entries(Notification.types[notification.type].params || []).map(([, value]) => notification[value.toString()]);

    const extractedQueryParams = Object.entries(Notification.types[notification.type].queryParams || {});
    const queryParams = Object.assign(
      {},
      ...extractedQueryParams.map(([property, value]) => ({ [property]: notification[value.toString()] })),
    );
    this.router.navigate([path, ...params], { queryParams });
  }
}
