import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class PwaInstallService {
  deferredPrompt: BeforeInstallPromptEvent;
  installed = false;

  get promptUser() {
    const dismissedUntil = localStorage.getItem('installDismissedUntil');
    return !!this.deferredPrompt && !this.installed && (!dismissedUntil || moment().isAfter(moment(dismissedUntil)));
  }

  install(): void {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
    }
  }

  dismiss(): void {
    this.deferredPrompt = null;
    const until = moment().add(2, 'days');
    localStorage.setItem('installDismissedUntil', until.toISOString());
  }
}
