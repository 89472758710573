import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService } from '@services/loader.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private readonly loader: LoaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.headers.has('http-loader-disable')) {
      return next.handle(this.removeHeaders(req));
    }

    this.loader.show(1000);
    return next.handle(req).pipe(
      finalize(() => {
        setTimeout(() => this.loader.hide(), 200);
      }),
    );
  }

  private removeHeaders(req: HttpRequest<any>) {
    return req.clone({
      headers: req.headers.delete('http-loader-disable', 'disable'),
    });
  }
}
