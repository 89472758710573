import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WebSocketService } from '@services/websocket.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TopNavBarComponent } from './components/topnavbar/topnavbar.component';

@Component({
  selector: 'gc-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss'],
})
export class SessionComponent implements OnInit, OnDestroy {
  @ViewChild('topNavBar') topNav: TopNavBarComponent;
  doBlur = false;
  private readonly destroyed$ = new Subject<void>();

  constructor(private readonly websocket: WebSocketService) {}

  ngOnInit() {
    this.websocket.connect().pipe(takeUntil(this.destroyed$)).subscribe();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.websocket.close();
  }
}
