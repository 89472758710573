import { MedicalAidOption } from './medical-aid-option.model';
import { MedicalAidScheme } from './medical-aid-scheme.model';

export interface MedicalAidData {
  main_member_id?: number;
  scheme: MedicalAidScheme;
  option: MedicalAidOption;
  number: string;
  dependant_type?: string;
  dependant_code?: string;
}

export class MedicalAid {
  mainMemberId: number;
  scheme: MedicalAidScheme;
  option: MedicalAidOption;
  number: string;
  dependantType: string;
  dependantCode: string;

  constructor(data: MedicalAidData) {
    this.number = data.number;
    this.mainMemberId = data.main_member_id;
    this.scheme = data.scheme;
    this.option = data.option;
    this.dependantType = data.dependant_type;
    this.dependantCode = data.dependant_code;
  }
  get complete(): boolean {
    const mainMemberComplete = !!this.mainMemberId;
    return mainMemberComplete && !!this.number && this.scheme.complete && this.option.complete;
  }

  get rawData(): MedicalAidData {
    return {
      main_member_id: this.mainMemberId,
      scheme: this.scheme,
      option: this.option,
      number: this.number,
      dependant_type: this.dependantType,
      dependant_code: this.dependantCode,
    };
  }
}
