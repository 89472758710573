import { AfterViewInit, Component, ElementRef, Input, Optional, Renderer2, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { AlertService } from '@services/alert.service';

interface ErrorMap {
  [key: string]: string;
}

@Component({
  selector: 'gc-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent implements AfterViewInit, ControlValueAccessor {
  @Input() value: any;
  @Input() type = 'text';
  @Input() statusIcon = null;
  @Input() autocomplete: string;
  @Input() label: string;
  @Input() error = true;
  @Input() hint: string;
  @Input() errorMap: string | ErrorMap;
  @Input() name: string;
  @Input() infoMessage?: string;
  @Input() infoTitle?: string;
  @Input() readonly = false;
  @Input() required = false;

  @ViewChild(MatInput) matInput: MatInput;
  @ViewChild('formField', { read: ElementRef })
  formField: ElementRef<HTMLElement>;

  onChange: any;
  onTouched: any;

  constructor(
    @Self()
    @Optional()
    public control: NgControl,
    private readonly renderer: Renderer2,
    private readonly alert: AlertService,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  get invalid() {
    return this.control ? this.control.invalid : false;
  }

  get errors() {
    return this.control ? this.control.errors : null;
  }

  get showError() {
    if (!this.control) {
      return false;
    }

    const { dirty, touched } = this.control;
    return this.invalid ? dirty || touched : false;
  }

  get touched() {
    return this.control ? this.control.touched : false;
  }

  get dirty() {
    return this.control ? this.control.dirty : false;
  }

  get showStatusIcon() {
    return this.statusIcon !== null;
  }

  get showInfo() {
    return !!(this.infoMessage && this.infoTitle);
  }

  get errorText() {
    if (this.error && this.errorMap) {
      return typeof this.errorMap === 'string' ? this.errorMap : this.errorMap[Object.keys(this.errors)[0]];
    }
  }

  get disabled() {
    return this.control?.disabled ?? false;
  }

  ngAfterViewInit() {
    if (!this.error) {
      const wrapper = this.formField.nativeElement.querySelector('.mat-form-field-wrapper');
      const underline = wrapper.querySelector('.mat-form-field-underline');
      this.renderer.setStyle(wrapper, 'padding-bottom', 0);
      this.renderer.setStyle(underline, 'bottom', 'auto');
    }
    this.updateStyles();
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onInputChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (this.onChange) {
      this.onChange(target.value);
    }
  }

  onInputBlur() {
    if (this.onTouched) {
      this.onTouched();
    }
    this.matInput.errorState = this.showError;
    this.updateStyles();
  }

  updateStyles() {
    const underline = this.formField.nativeElement.querySelector('.mat-form-field-underline > .mat-form-field-ripple');
    if (this.control && this.control.touched && this.control.valid) {
      this.renderer.setStyle(underline, 'background-color', 'var(--accent-color)');
      this.renderer.setStyle(underline, 'opacity', 1);
      this.renderer.setStyle(underline, 'transform', 'initial');
    } else {
      this.renderer.removeStyle(underline, 'background-color');
      this.renderer.removeStyle(underline, 'opacity');
      this.renderer.removeStyle(underline, 'transform');
    }
  }

  openInfoDialog() {
    this.alert.messageDialog$(this.infoTitle, this.infoMessage, 'question');
  }
}
