import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@services/alert.service';

import { LoaderService } from '@services/loader.service';

@Component({
  selector: 'gc-accept-invite',
  templateUrl: './accept-invite.component.html',
  styleUrls: ['./accept-invite.component.scss'],
})
export class AcceptInviteComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly http: HttpClient,
    private readonly loader: LoaderService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.loader.show();
    const hash = this.route.snapshot.paramMap.get('hash');
    this.acceptInvite(hash);
  }

  acceptInvite(hash: string) {
    this.http.patch(`/api/family/invites/${hash}`, { accept: true }).subscribe(
      () => {
        this.router.navigate(['']);
        this.loader.hide();
      },
      () => {
        this.alertService.messageDialog$(
          'Invalid family invite link',
          'The link you have used is no longer valid, please ask your family owner to send you a new one.',
          'error',
        );
        this.router.navigate(['']);
      },
    );
  }
}
