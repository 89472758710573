import { Injectable } from '@angular/core';
import { VERSION } from 'src/environments/version';

@Injectable({
  providedIn: 'root',
})
export class AboutService {
  version() {
    return VERSION.version.replace(/^v/, '');
  }

  date() {
    return new Date(VERSION.releaseDate);
  }
}
