<div class="body">
  <div class="content" gdColumns="2fr repeat(3, 1fr)" gdColumns.lt-md="100%" gdGap="16px">
    <section>
      <h2>Notifications</h2>
      <gc-swipe-notifications></gc-swipe-notifications>
    </section>
    <section gdColumn="auto" gdColumn.gt-sm="2 / span 3">
      <h2>Quick Actions</h2>
      <mat-grid-list
        class="quick-actions"
        [cols]="quickActions.length <= 4 || (isMobile$ | async) ? 2 : 3"
        gutterSize="10px"
        rowHeight="110px"
      >
        <mat-grid-tile *ngFor="let action of quickActions">
          <button mat-button [routerLink]="action.path">
            <mat-icon [svgIcon]="action.icon"></mat-icon>
            <div [innerHTML]="action.label"></div>
          </button>
        </mat-grid-tile>
      </mat-grid-list>
    </section>
    <section fxHide.lt-md>
      <h2>Upcoming Bookings</h2>
      <div *ngIf="latestBooking; else loadingBookings" fxLayout="column" class="latest-bookings">
        <div *ngFor="let latestBooking of latestBookingList">
          <mat-card>
            <gc-booking-display-card [booking]="latestBooking"></gc-booking-display-card>
          </mat-card>
        </div>
        <div class="view-more uppercase" fxLayout="row" fxLayoutAlign="end center">
          <span routerLink="/bookings/my-bookings">View More</span>
          <mat-icon svgIcon="chevron-right"></mat-icon>
        </div>
      </div>
      <ng-template #loadingBookings>
        <div fxLayout="column" fxLayoutGap="10px" *ngIf="isLoadingBooking; else noBooking">
          <span>Loading bookings..</span>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <ng-template #noBooking>
          <div fxLayout="column">
            <div>No upcoming bookings</div>
            <div class="view-more uppercase" fxLayout="row" fxLayoutAlign="end center">
              <span routerLink="/bookings/find-a-doctor">Make a Booking</span>
              <mat-icon svgIcon="chevron-right"></mat-icon>
            </div>
          </div>
        </ng-template>
      </ng-template>
    </section>
    <section fxHide.lt-md>
      <h2>My Saved Doctors</h2>
      <div *ngIf="doctor; else loadingDoctors" fxLayout="column">
        <div fxLayout="row">
          <gc-doctor-booking-card [doctor]="doctor"></gc-doctor-booking-card>
        </div>
        <div class="view-more uppercase" fxLayout="row" fxLayoutAlign="end center">
          <span routerLink="/bookings/saved-doctors">View More</span>
          <mat-icon svgIcon="chevron-right"></mat-icon>
        </div>
      </div>
      <ng-template #loadingDoctors>
        <div fxLayout="column" fxLayoutGap="10px" *ngIf="isLoadingDoctor; else noDoctor">
          <span>Loading doctors..</span>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <ng-template #noDoctor>
          <div fxLayout="column">
            <div>No saved doctors</div>
            <div class="view-more uppercase" fxLayout="row" fxLayoutAlign="end center">
              <span routerLink="/bookings/find-a-doctor">Add a Doctor</span>
              <mat-icon svgIcon="chevron-right"></mat-icon>
            </div>
          </div>
        </ng-template>
      </ng-template>
    </section>
    <section fxHide.lt-md>
      <h2>Outstanding Payments</h2>
      <div *ngIf="!isLoadingPayment; else loadingPayments" fxLayout="column">
        <ng-container *ngIf="recentPayment; else noPayments">
          <mat-card>
            <gc-payment-display-card [payment]="recentPayment"></gc-payment-display-card>
          </mat-card>
          <div class="view-more uppercase" fxLayout="row" fxLayoutAlign="end center">
            <span routerLink="/payments">View More</span>
            <mat-icon svgIcon="chevron-right"></mat-icon>
          </div>
        </ng-container>
        <ng-template #noPayments>
          <ng-container *ngIf="isIdNumberPresent$ | async; else cantRetrievePayments">
            <div>No outstanding payments</div>
          </ng-container>
        </ng-template>
        <ng-template #cantRetrievePayments>
          <div fxLayout="column">
            <div>Can't retrieve payments. No ID number on profile.</div>
            <div class="view-more uppercase" fxLayout="row" fxLayoutAlign="end center">
              <span routerLink="/setup/my-account/">Update Profile</span>
              <mat-icon svgIcon="chevron-right"></mat-icon>
            </div>
          </div>
        </ng-template>
      </div>
      <ng-template #loadingPayments>
        <div fxLayout="column" fxLayoutGap="10px" *ngIf="isLoadingPayment; else noPayment">
          <span>Loading payments..</span>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <ng-template #noPayment>
          <div>No outstanding payments</div>
        </ng-template>
      </ng-template>
    </section>
    <section fxHide.lt-md>
      <h2>Consent Forms</h2>
      <div *ngIf="!isLoadingConsent; else loadingConsents">
        <mat-card
          *ngIf="recentConsent; else noConsents"
          fxLayout="row"
          fxLayoutGap="16px"
          fxLayoutAlign="start center"
          class="consent-card"
          [routerLink]="['/health', 'consent-forms', recentConsent.uuid]"
        >
          <mat-icon svgIcon="thumbs-up-down" style="height: 48px; width: 48px" color="primary"></mat-icon>
          <div fxLayout="column" fxLayoutAlign="start start">
            <span class="mat-overline" style="font-size: 10px">{{ recentConsent.created.toDate() | localizedDate : 'mediumDate' }}</span>
            <span class="consent-title">
              {{ recentConsent.healthWindow ? 'HW Consent' : 'Consent Form' }} from {{ recentConsent.doctor }}
            </span>
          </div>
        </mat-card>
        <ng-template #noConsents>
          <div>No outstanding consent forms</div>
        </ng-template>
        <div class="view-more uppercase" fxLayout="row" fxLayoutAlign="end center" routerLink="/health/consent-forms">
          <span>View More</span>
          <mat-icon svgIcon="chevron-right"></mat-icon>
        </div>
      </div>
      <ng-template #loadingConsents>
        <div fxLayout="column" fxLayoutGap="10px">
          <span>Loading consent forms...</span>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </ng-template>
    </section>
  </div>
</div>
