<mat-card class="have-account">
  <div class="img-container center">
    <img src="assets/images/myGCLogo_default-white.svg" alt="myGC Logo" />
  </div>
  <mat-card-header class="text">
    <mat-card-title>Already have an account?</mat-card-title>
    <mat-card-subtitle>
      Log back in to access all
      <br />
      <b><i>myGC's</i></b>
      great features!
    </mat-card-subtitle>
  </mat-card-header>
  <div class="login-button">
    <button class="login-btn" mat-raised-button type="button" routerLink="/login">
      <mat-icon svgIcon="login"></mat-icon>
      <span>Log in</span>
    </button>
  </div>
</mat-card>
