import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[gcFormField]',
})
export class FormFieldDirective implements AfterViewInit {
  @Input() gcFormFieldNoError = null;
  constructor(private readonly el: ElementRef<HTMLElement>, private readonly renderer: Renderer2, private readonly control: NgControl) {}

  get formField() {
    return this.el.nativeElement.closest('mat-form-field');
  }

  ngAfterViewInit() {
    if (this.gcFormFieldNoError !== null) {
      const wrapper = this.formField.querySelector('.mat-form-field-wrapper');
      const underline = wrapper.querySelector('.mat-form-field-underline');
      this.renderer.setStyle(wrapper, 'padding-bottom', 0);
      this.renderer.setStyle(underline, 'bottom', 'auto');
    }
  }

  @HostListener('change')
  @HostListener('selectionChange')
  onChange() {
    if (this.control.valid) {
      const underline = this.formField.querySelector('.mat-form-field-underline > span.mat-form-field-ripple');
      this.renderer.setStyle(underline, 'background-color', 'var(--accent-color)');
      this.renderer.setStyle(underline, 'opacity', 1);
      this.renderer.setStyle(underline, 'transform', 'initial');
    }
  }
}
