import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

export interface FilterType<T = string> {
  title: string;
  type: T;
  icon: string;
  selected: boolean;
}

@Component({
  selector: 'gc-type-filter',
  templateUrl: './type-filter.component.html',
  styleUrls: ['./type-filter.component.scss'],
})
export class TypeFilterComponent implements OnChanges {
  @Input() types: FilterType[] = [];
  @Input() data = [];
  @Input() minimal = false;
  @Output() filterCallback: EventEmitter<any> = new EventEmitter();
  filteredData = [];
  @Input() funcFilter: (n: any, s: string[]) => boolean;

  ngOnChanges() {
    this.doFilter();
  }

  onToggleType(type: FilterType) {
    type.selected = !type.selected;
    this.doFilter();
  }

  private doFilter() {
    const selectedTypes = this.types.filter((t) => t.selected).map((t) => t.type);
    this.filteredData = selectedTypes.length
      ? this.data.filter((n) => {
          if (this.funcFilter) {
            return this.funcFilter(n, selectedTypes);
          }
          return selectedTypes.includes(n.type);
        })
      : this.data;
    this.filterCallback.emit(this.filteredData);
  }

  clearToggles() {
    this.types.forEach((t) => (t.selected = false));
    this.filteredData = this.data;
    this.filterCallback.emit(this.filteredData);
  }
}
